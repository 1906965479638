import React, { Component } from 'react';

function scrollToFooter()
{
    // Check if parent exists
    if (window.top !== window.self)
    {
        const message = {
            type: 'scroll_to_footer'
        };
        window.parent.postMessage(message, '*');
    }
    else
    {
        let elements = document.getElementsByClassName('body-footer');
        if (elements.length !== 0)
        {
            elements[0].scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }
    }
}

// Function save
class FunctionSave extends Component
{
    render()
    {
        if (this.props.function_configurator_check_valid() === true)
        {
            let window_style_mobile = {
                top: (this.props.window_height-160)+'px'
            };

            let window_style_tablet = {
                top: (this.props.window_height-64)+'px'
            };

            let window_style_desktop = {
                top: (this.props.window_height-64)+'px'
            };

            if (this.props.view_mode === 1)
            {
                window_style_mobile = {
                    top: (this.props.window_height-140)+'px'
                };  

                window_style_tablet = {
                    top: (this.props.window_height-48)+'px'
                };  
                
                window_style_desktop = {
                    top: (this.props.window_height-120)+'px'
                }; 
            }

            let total = 0;
            for (let i = 0; i < this.props.content_stone_types.length; i++)
            {
                // Check if this stone is in the current collection
                if (this.props.content_stone_types[i][1] === this.props.content_stone_collections[this.props.configurator_stone_collection][0])
                {
                    // Check if this stone is selected
                    if (this.props.configurator_stone_multi[i] > 0)
                        total++;
                }
            }

            if (total > 0)
            {
                return (
                    <React.Fragment>
                        <div className="function-button-save-mobile d-block d-md-none" style={window_style_mobile} onClick={() => {
                            this.props.function_favourites_save_cookie();
                            this.props.function_popup_open('save');
                        }}><span>Bewaar</span></div>

                        <div className="function-button-save-tablet d-none d-md-block d-lg-none" style={window_style_tablet} onClick={() => {
                            this.props.function_favourites_save_cookie();
                            this.props.function_popup_open('save');
                        }}><span></span></div>

                        <div className="function-button-save-desktop d-none d-lg-block" style={window_style_desktop} onClick={() => {
                            this.props.function_favourites_save_cookie();
                            this.props.function_popup_open('save');
                        }}><span>{this.props.function_translate('function_save')}</span>
                        </div>
                    </React.Fragment>
                );
            }
            else
            {
                return (
                    <React.Fragment>
                        <div className="function-button-save-mobile d-block d-md-none" style={window_style_mobile} onClick={() => {
                            this.props.function_popup_open('cannot_save');
                        }}><span>Bewaar</span></div>

                        <div className="function-button-save-tablet d-none d-md-block d-lg-none" style={window_style_tablet} onClick={() => {
                            this.props.function_popup_open('cannot_save');
                        }}><span></span></div>

                        <div className="function-button-save-desktop d-none d-lg-block" style={window_style_desktop} onClick={() => {
                            this.props.function_popup_open('cannot_save');
                        }}><span>{this.props.function_translate('function_save')}</span>
                        </div>
                    </React.Fragment>
                );
            }
        }
        else
        {
            return (
                <React.Fragment></React.Fragment>
            );
        }
    }
}

// Function downloads
class FunctionDownloads extends Component
{
    render()
    {
        if (this.props.function_configurator_check_valid() === true)
        {
            let window_style_mobile = {
                top: (this.props.window_height-112)+'px'
            };

            let window_style_tablet = {
                top: (this.props.window_height-64)+'px'
            };

            let window_style_desktop = {
                top: (this.props.window_height-64)+'px'
            };

            if (this.props.view_mode === 1)
            {
                window_style_mobile = {
                    top: (this.props.window_height-96)+'px'
                }; 

                window_style_tablet = {
                    top: (this.props.window_height-48)+'px'
                }; 
                
                window_style_desktop = {
                    top: (this.props.window_height-120)+'px'
                };   
            }

            let allow_download = true;

            // Disallow stone mode multi where nothing is selected
            let stone_factors_total = 0;
            for (let i = 0; i < this.props.content_stone_types.length; i++)
            {
                // Check if this stone is in the current collection
                if (this.props.content_stone_types[i][1] === this.props.content_stone_collections[this.props.configurator_stone_collection][0])
                {
                    if (this.props.configurator_stone_multi[i] > 0)
                    {
                        stone_factors_total++;
                    }
                }
            }

            if (stone_factors_total === 0)
                allow_download = false;

            if (allow_download === true)
            {
                return (
                    <React.Fragment>
                        <div className="function-button-downloads-mobile d-block d-md-none" style={window_style_mobile} onClick={() => this.props.function_popup_open('downloads')}>
                            <span>{this.props.function_translate('function_download')}</span>
                        </div>

                        <div className="function-button-downloads-tablet d-none d-md-block d-lg-none" style={window_style_tablet} onClick={() => this.props.function_popup_open('downloads')}>
                            <span>{this.props.function_translate('function_download')}</span>
                        </div>

                        <div className="function-button-downloads-desktop d-none d-lg-block" style={window_style_desktop} onClick={() => this.props.function_popup_open('downloads')}>
                            <span>{this.props.function_translate('function_download')}</span>
                        </div>
                    </React.Fragment>
                );
            }
            else
            {
                return (
                    <React.Fragment>
                        <div className="function-button-downloads-mobile d-block d-md-none" style={window_style_mobile} onClick={() => this.props.function_popup_open('cannot_download')}>
                            <span>{this.props.function_translate('function_download')}</span>
                        </div>

                        <div className="function-button-downloads-tablet d-none d-md-block d-lg-none" style={window_style_tablet} onClick={() => this.props.function_popup_open('cannot_download')}>
                            <span>{this.props.function_translate('function_download')}</span>
                        </div>

                        <div className="function-button-downloads-desktop d-none d-lg-block" style={window_style_desktop} onClick={() => this.props.function_popup_open('cannot_download')}>
                            <span>{this.props.function_translate('function_download')}</span>
                        </div>
                    </React.Fragment>
                );
            }
        }
        else
        {
            return (
                <React.Fragment></React.Fragment>
            );
        }
    }
}

// Function productinfo
class FunctionProductinfo extends Component
{
    render()
    {
        if (this.props.function_configurator_check_valid() === true)
        {
            let window_style_mobile = {
                top: (this.props.window_height-64)+'px'
            };

            let window_style_desktop = {
                top: (this.props.window_height-64)+'px'
            };

            if (this.props.view_mode === 1)
            {
                window_style_mobile = {
                    top: (this.props.window_height-48)+'px'
                }; 

                window_style_desktop = {
                    top: (this.props.window_height-120)+'px'
                };   
            }

            return (
                <React.Fragment>
                    <div className="function-button-productinfo-mobile d-block d-lg-none" style={window_style_mobile} onClick={() => scrollToFooter()}>
                        <span>{this.props.function_translate('function_productinfo')}</span>
                    </div>

                    <div className="function-button-productinfo-desktop d-none d-lg-block" style={window_style_desktop} onClick={() => scrollToFooter()}>
                        <span>{this.props.function_translate('function_productinfo')}</span>
                    </div>
                </React.Fragment>
            )
        }
        else
        {
            return (
                <React.Fragment></React.Fragment>
            );
        }
    }
}

// -----------------------------------------------------------------------
    
class Function extends Component
{
    render()
    {
        return (
            <React.Fragment>
                <FunctionSave
                    content_stone_collections={this.props.content_stone_collections}
                    content_stone_types={this.props.content_stone_types}
                    configurator_stone_collection={this.props.configurator_stone_collection}
                    configurator_stone_multi={this.props.configurator_stone_multi}
                    function_configurator_check_valid={this.props.function_configurator_check_valid}
                    view_mode={this.props.view_mode}
                    window_height={this.props.window_height}
                    function_translate={this.props.function_translate}
                    function_popup_open={this.props.function_popup_open}
                    function_favourites_save_cookie={this.props.function_favourites_save_cookie} />

                <FunctionDownloads
                    content_stone_collections={this.props.content_stone_collections}
                    content_stone_types={this.props.content_stone_types}
                    configurator_stone_collection={this.props.configurator_stone_collection}
                    configurator_stone_multi={this.props.configurator_stone_multi}
                    view_mode={this.props.view_mode}
                    window_height={this.props.window_height}
                    function_configurator_check_valid={this.props.function_configurator_check_valid}
                    function_translate={this.props.function_translate}
                    function_popup_open={this.props.function_popup_open} />
                    
                <FunctionProductinfo
                    function_configurator_check_valid={this.props.function_configurator_check_valid}
                    view_mode={this.props.view_mode}
                    window_height={this.props.window_height}
                    function_translate={this.props.function_translate} />

            </React.Fragment>
        )
    }
}

export default Function