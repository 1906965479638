import React, { Component } from 'react';

// Warning

class Warning extends Component
{
    render()
    {
        if (this.props.content_loaded === true)
        {
            let condition_claustra_vertical = false;
            let condition_claustra_photo = false;

            if (this.props.function_stone_check_textures_loaded() === true)
            {
                condition_claustra_vertical = (this.props.configurator_intro === false && this.props.content_bond_types[this.props.configurator_bond_type][0] === 'claustraverband' && this.props.configurator_bond_orientation === 1);

                condition_claustra_photo = (this.props.configurator_intro === false && this.props.content_bond_types[this.props.configurator_bond_type][0] === 'claustraverband' && this.props.view_mode === 1);
            }

            /*
            let condition_claustra_vertical = (this.props.configurator_intro === false && this.props.content_bond_types[this.props.configurator_bond_type][0] === 'claustraverband' && this.props.configurator_bond_orientation === 1);
            
            let condition_claustra_photo = (this.props.configurator_intro === false && this.props.view_mode === 1 && this.props.content_bond_types[this.props.configurator_bond_type][0] === 'claustraverband');
            */

            if (condition_claustra_vertical === true)
            {
                return (
                    <div className="warning">
                        <div className="warning-content">
                            {this.props.function_translate('warning_claustra_vertical')}
                        </div>
                    </div>
                )
            }
            else if (condition_claustra_photo === true)
            {
                return (
                    <div className="warning">
                        <div className="warning-content">
                            {this.props.function_translate('warning_claustra_photo')}
                        </div>
                    </div>
                )
            }
            else
            {
                return (
                    <React.Fragment>
                    </React.Fragment>
                )
            }
        }
        else
        {
            return (
                <React.Fragment>
                </React.Fragment>
            )
        }
    }
}

export default Warning