import React, { Component } from 'react';

function ShowTrueFalse(bool)
{
    if (bool === true)
        return 1;
    else
        return 0;
}

class Debug extends Component
{
    constructor(props)
    {
        super(props);
        
        this.state = {
            visible: true
        }

        this.debug_hide = this.debug_hide.bind(this);
    }

    debug_hide()
    {
        this.setState({ 
            visible: false
        })
    }

    render() {
        if (this.state.visible === true)
        {
            return (
                <div className="debug">
                    <b>DEBUG HELPER</b><br/>
                    <br/>
                    <button onClick={() => this.debug_hide()}>Hide debug helper</button>
                    <br/>
                    <br/>
                    favourites={ShowTrueFalse(this.props.favourites)}<br/>
                    favourites_loaded_counter={this.props.favourites_loaded_counter}<br/>
                    favourites_loaded_target={this.props.favourites_loaded_target}<br/>
                    favourites_loaded={ShowTrueFalse(this.props.favourites_loaded)}<br/>
                    canvas_width={this.props.canvas_width}<br/>
                    canvas_height={this.props.canvas_height}<br/>
                    window_height={this.props.window_height}<br/>
                    view_rows={this.props.view_rows}<br/>
                    content_stone_loaded_counter={this.props.content_stone_loaded_counter}<br/>
                    autosave_loaded_counter={this.props.autosave_loaded_counter}<br/>
                    autosave_loaded_target={this.props.autosave_loaded_target}<br/>
                    autosave_loaded={ShowTrueFalse(this.props.autosave__loaded)}<br/>
                    intro_loaded_counter={this.props.intro_loaded_counter}<br/>
                    intro_loaded_target={this.props.intro_loaded_target}<br/>
                    intro_loaded={ShowTrueFalse(this.props.intro_loaded)}<br/>
                    photo_current={this.props.photo_current}<br/>
                    photo_size={this.props.photo_size}<br/>
                    photo_content_loaded_counter={this.props.content_photo_loaded_counter}<br/>
                    photo_loaded_counter={this.props.photo_loaded_counter}<br/>
                    photo_loaded_target={this.props.photo_loaded_target}<br/>
                    photo_loaded={ShowTrueFalse(this.props.photo_loaded)}<br/>
                    configurator_stone_multi={this.props.configurator_stone_multi}<br/>
                    configurator_spacing_type={this.props.configurator_spacing_type}<br/>
                    configurator_spacing_type_h={this.props.configurator_spacing_type_h}<br/>
                    configurator_spacing_type_v={this.props.configurator_spacing_type_v}<br/>
                    configurator_spacing_color={this.props.configurator_spacing_color}<br/>
                    configurator_bond_type={this.props.configurator_bond_type}<br/>
                    configurator_bond_orientation={this.props.configurator_bond_orientation}<br/>
                    configurator_changed={ShowTrueFalse(this.props.configurator_changed)}<br/>
                    debug_stone_count={this.props.debug_stone_count}<br/>
                    debug_stone_width_full={this.props.debug_stone_width_full}<br/>
                    debug_stone_width_half={this.props.debug_stone_width_half}<br/>
                    debug_stone_height={this.props.debug_stone_height}<br/>
                    debug_page_width_mm={this.props.debug_page_width_mm}<br/>
                    debug_page_height_mm={this.props.debug_page_height_mm}<br/>
                    debug_page_text_count={this.props.debug_page_text_count}<br/>
                    path={window.location.origin}
                </div>
            )
        }
        else return null;
    }
}

export default Debug