import React, { Component } from 'react';

function scrollToConfigurator()
{
    // Check if parent exists
    if (window.top !== window.self)
    {
        const message = {
            type: 'scroll_to_configurator'
        };
        window.parent.postMessage(message, '*');
    }
    else
    {
        let elements = document.getElementsByClassName('body-configurator');
        if (elements.length !== 0)
        {
            elements[0].scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }
    }
}

// Title

class FooterTitle extends Component
{
    render()
    {
        return (
            <div className="footer-title">{this.props.function_translate('footer_title')}</div>
        );
    }
}

// Configuration

class FooterConfiguration extends Component
{
    render()
    {
        let spacing_color = this.props.content_spacing_colors[this.props.configurator_spacing_color][1+this.props.translation_language_index];
        
        if (this.props.configurator_spacing_type === 0)
            spacing_color = this.props.function_translate('stone_spacing_none');

        return (
            <div className="footer-configuration clearfix">
                <div className="footer-column">
                    <div className="footer-text">
                        {this.props.function_translate('configurator_collection')}
                    </div>
                </div>
                <div className="footer-column">
                    <div className="footer-text-dark">
                        {this.props.content_stone_collections[this.props.configurator_stone_collection][2+this.props.translation_language_index]}
                    </div>
                </div>
                <div className="footer-column">
                    <div className="footer-text">
                        {this.props.function_translate('configurator_bond_type')}
                    </div>
                </div>
                <div className="footer-column">
                    <div className="footer-text-dark">
                        {this.props.content_bond_types[this.props.configurator_bond_type][1+this.props.translation_language_index]}
                    </div>
                </div>
                <div className="footer-column">
                    <div className="footer-text">
                        {this.props.function_translate('configurator_orientation')}
                    </div>
                </div>
                <div className="footer-column">
                    <div className="footer-text-dark">
                        {this.props.content_bond_orientations[this.props.configurator_bond_orientation][1+this.props.translation_language_index]}
                    </div>
                </div>
                <div className="footer-column">
                    <div className="footer-text">
                        {this.props.function_translate('configurator_spacing')}
                    </div>
                </div>
                <div className="footer-column">
                    <div className="footer-text-dark">
                        {this.props.content_spacing_types[this.props.configurator_spacing_type][1+this.props.translation_language_index]}
                    </div>
                </div>
                <div className="footer-column">
                    <div className="footer-text">
                        {this.props.function_translate('configurator_spacing_color')}
                    </div>
                </div>
                <div className="footer-column">
                    <div className="footer-text-dark">
                        {spacing_color}
                    </div>
                </div>
            </div>
        );
    }
}

// Stones

class FooterStones extends Component
{
    render()
    {
        const stones = [];

        // Pre-calc total (used in percentages display)
        let total = 0;
        for (let i = 0; i < this.props.content_stone_types.length; i++)
        {
            // Check if this stone is in the current collection
            if (this.props.content_stone_types[i][1] === this.props.content_stone_collections[this.props.configurator_stone_collection][0])
            {
                if (this.props.configurator_stone_multi[i] > 0)
                    total += this.props.configurator_stone_multi[i];
            }
        }
        
        let key = 0;
        for (let i = 0; i < this.props.content_stone_types.length; i++)
        {
            // Check if this stone is in the current collection
            if (this.props.content_stone_types[i][1] === this.props.content_stone_collections[this.props.configurator_stone_collection][0])
            {
                if (this.props.configurator_stone_multi[i] > 0)
                {
                    key++;

                    let style = {
                        backgroundImage: 'url(../texture/preview/'+this.props.content_stone_collections[this.props.configurator_stone_collection][1]+'_'+this.props.content_stone_types[i][0]+'_'+this.props.content_spacing_types[this.props.configurator_spacing_type][0]+'_000000_'+this.props.content_bond_types[this.props.configurator_bond_type][0]+'_'+this.props.content_bond_orientations[this.props.configurator_bond_orientation][0]+'_preview.jpg)'
                    };

                    stones.push(
                        <div className="clearfix" key={key}>
                            <div className="footer-column">
                                <div className="footer-stone-image" style={style}></div>
                            </div>
                            <div className="footer-column">
                                <div className="footer-text-dark">
                                    {this.props.content_stone_types[i][2+this.props.translation_language_index]}
                                </div>
                                <div className="footer-text">
                                    {this.props.function_translate('footer_combination_percentage')} {Math.floor((this.props.configurator_stone_multi[i]/total)*100)}%
                                </div>
                            </div>
                        </div>
                    );
                }
            }
        }

        return (
            <div className="footer-text">
                {this.props.function_translate('footer_stones')}

                <div className="footer-configuration clearfix">
                    {stones}
                </div>
            </div>
        );
    }
}

// Calculator

class FooterCalculator extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            form_type: '0',
            form_m2: '',
        }
        
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event)
    {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event)
    {
        // Do nothing

        event.preventDefault();
    }

    render()
    {
        const form_type_dropdown = [];

        form_type_dropdown.push(
            <option key={0} value={0}>{this.props.function_translate('form_stones')}</option>
        );

        form_type_dropdown.push(
            <option key={1} value={1}>{this.props.function_translate('form_strips')}</option>
        );

        let footer_calculator_surface = '-';
        let footer_calculator_stones = '-';
        let footer_calculator_weight = '-';
        let footer_calculator_pallets = '-';

        if (this.state.form_m2 !== '')
        {
            let calc_stones = this.state.form_m2*this.props.content_spacing_types[this.props.configurator_spacing_type][7];

            let calc_weight = 0;
            if (this.state.form_type === '0')
                calc_weight = 5.10;
            else
                calc_weight = 1.06;
            
            let calc_weight_total = calc_stones*calc_weight;
            let str_calc_weight_total = parseFloat(calc_weight_total).toLocaleString();
            
            let calc_perpallet;
            if (this.state.form_type === '0')
                calc_perpallet = 216;
            else
                calc_perpallet = 520;

            let calc_pallets = parseInt(Math.ceil(calc_stones/calc_perpallet)).toLocaleString();

            footer_calculator_surface = parseInt(this.state.form_m2).toLocaleString()+' m²';

            footer_calculator_stones = '±'+parseInt(calc_stones).toLocaleString();

            if (this.state.form_type === '0')
                footer_calculator_weight = '±'+str_calc_weight_total+' kg (5,10 '+this.props.function_translate('footer_calculator_kg_per_stone')+')';
            else
                footer_calculator_weight = '±'+str_calc_weight_total+' kg (1,06 '+this.props.function_translate('footer_calculator_kg_per_strip')+')';

            footer_calculator_pallets = calc_pallets+' ('+calc_perpallet+' '+this.props.function_translate('footer_calculator_stones_per_pallet')+')';
        }

        return (
            <div className="footer-calculator">
                <div className="footer-text">
                    <div className="footer-title">
                        {this.props.function_translate('footer_calculate_amount')}
                    </div>

                    <form onSubmit={this.handleSubmit} style={{paddingBottom: 18+"px"}}>
                        <select
                            className = "form-singleline-dropdown-half"
                            name = "form_type"
                            value = {this.state.form_type}
                            onChange = {this.handleInputChange}>
                            
                            {form_type_dropdown}
                        </select>

                        <input
                            className = "form-singleline-half-right"
                            name = "form_m2"
                            type = "number"
                            min = "0"
                            value = {this.state.form_m2}
                            onChange = {this.handleInputChange}
                            placeholder = {this.props.function_translate('form_m2')} 
                            required />
                    </form>

                    <div className="footer-configuration clearfix">
                        <div className="footer-column">
                            <div className="footer-text">
                                {this.props.function_translate('footer_calculator_surface')}
                            </div>
                        </div>
                        <div className="footer-column">
                            <div className="footer-text-dark">
                                {footer_calculator_surface}
                            </div>
                        </div>
                        
                        <div className="footer-column">
                            <div className="footer-text">
                                {this.props.function_translate('footer_calculator_stones')}
                            </div>
                        </div>
                        <div className="footer-column">
                            <div className="footer-text-dark">
                                {footer_calculator_stones}
                            </div>
                        </div>

                        <div className="footer-column">
                            <div className="footer-text">
                                {this.props.function_translate('footer_calculator_weight')}
                            </div>
                        </div>
                        <div className="footer-column">
                            <div className="footer-text-dark">
                                {footer_calculator_weight}
                            </div>
                        </div>

                        <div className="footer-column">
                            <div className="footer-text">
                                {this.props.function_translate('footer_calculator_pallets')}
                            </div>
                        </div>
                        <div className="footer-column">
                            <div className="footer-text-dark">
                                {footer_calculator_pallets}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// Functions

class FooterFunctions extends Component
{
    render()
    {
        let button_download_pdf = '';

        if (this.props.function_configurator_check_valid() === true)
        {
            let total = 0;
            for (let i = 0; i < this.props.content_stone_types.length; i++)
            {
                // Check if this stone is in the current collection
                if (this.props.content_stone_types[i][1] === this.props.content_stone_collections[this.props.configurator_stone_collection][0])
                {
                    if (this.props.configurator_stone_multi[i] > 0)
                        total += this.props.configurator_stone_multi[i];
                }
            }
            
            if (total > 0)
            {
                // Something is selected

                button_download_pdf = (
                    <div className="footer-button-download" onClick={() => {
                        this.props.function_popup_open('productinfo');
                        scrollToConfigurator();
                    }}>
                        <span>{this.props.function_translate('function_download_pdf')}</span>
                    </div>
                );
            }
            else
            {
                // Nothing selected yet

                button_download_pdf = (
                    <div className="footer-button-download" onClick={() => {
                        this.props.function_popup_open('cannot_download');
                        scrollToConfigurator();
                    }}>
                        <span>{this.props.function_translate('function_download_pdf')}</span>
                    </div>
                );
            }
        }

        return (
            <React.Fragment>
                {button_download_pdf}

                <div className="footer-button-order">
                    <span>{this.props.function_translate('footer_order')}</span>
                </div>

                <div className="footer-help">
                    {this.props.function_translate('footer_help1')}<br/>{this.props.function_translate('footer_help2')} <a className="footer-help-link" href={this.props.function_translate('footer_faq')} target="_blank" rel="noreferrer">FAQ</a> {this.props.function_translate('footer_help3')} <a className="footer-help-link-color" href={'tel:'+this.props.function_translate('footer_phonenumber')} rel="noreferrer">{this.props.function_translate('footer_phonenumber')}</a> {this.props.function_translate('footer_or')} <a className="footer-help-link-color" href={"mailto:"+this.props.function_translate('footer_email')}>{this.props.function_translate('footer_email')}</a>.
                </div>
            </React.Fragment>
        );
    }
}

// -----------------------------------------------------------------------

// Footer

class Footer extends Component
{
    render()
    {
        return (
            <div className="footer-container">
                <div className="row">
                    <div className="col-12">
                        <FooterTitle
                            function_translate={this.props.function_translate} />
                    </div>

                    <div className="col-12 col-md-6">
                        <FooterConfiguration
                            translation_language_index={this.props.translation_language_index}
                            content_stone_collections={this.props.content_stone_collections}
                            content_bond_types={this.props.content_bond_types}
                            content_bond_orientations={this.props.content_bond_orientations}
                            content_spacing_types={this.props.content_spacing_types}
                            content_spacing_colors={this.props.content_spacing_colors}
                            configurator_stone_collection={this.props.configurator_stone_collection}
                            configurator_bond_type={this.props.configurator_bond_type}
                            configurator_bond_orientation={this.props.configurator_bond_orientation}
                            configurator_spacing_type={this.props.configurator_spacing_type}
                            configurator_spacing_color={this.props.configurator_spacing_color}
                            function_translate={this.props.function_translate} />

                        <FooterStones
                            translation_language_index={this.props.translation_language_index}
                            content_stone_collections={this.props.content_stone_collections}
                            content_stone_types={this.props.content_stone_types}
                            content_bond_types={this.props.content_bond_types}
                            content_bond_orientations={this.props.content_bond_orientations}
                            content_spacing_types={this.props.content_spacing_types}
                            configurator_stone_collection={this.props.configurator_stone_collection}
                            configurator_stone_multi={this.props.configurator_stone_multi}
                            configurator_bond_type={this.props.configurator_bond_type}
                            configurator_bond_orientation={this.props.configurator_bond_orientation}
                            configurator_spacing_type={this.props.configurator_spacing_type}
                            function_translate={this.props.function_translate} />
                        
                        <FooterCalculator
                            content_spacing_types={this.props.content_spacing_types}
                            configurator_spacing_type={this.props.configurator_spacing_type}
                            function_translate={this.props.function_translate} />
                    </div>
                    <div className="d-none d-lg-block col-lg-3"></div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <FooterFunctions
                            content_stone_collections={this.props.content_stone_collections}
                            content_stone_types={this.props.content_stone_types}
                            configurator_stone_collection={this.props.configurator_stone_collection}
                            configurator_stone_multi={this.props.configurator_stone_multi}
                            function_configurator_check_valid={this.props.function_configurator_check_valid}
                            function_favourites_save_cookie={this.props.function_favourites_save_cookie}
                            function_translate={this.props.function_translate}
                            function_popup_open={this.props.function_popup_open} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer