import React, { Component } from 'react';

// -----------------------------------------------------------------------

// Start

class Start extends Component
{
    constructor(props)
    {
        // Props
        super(props);

        if (this.props.autosave_found === true)
        {
            this.canvas_autosave_resize = this.canvas_autosave_resize.bind(this);
            this.canvas_autosave_reference = React.createRef();
        }
    }

    componentDidMount()
    {
        if (this.props.autosave_found === true)
        {
            // Resize
            var canvas = document.getElementById('canvas_autosave_id');
            canvas.width = 648;
            canvas.height = 160;

            // Draw
            var context = this.canvas_autosave_reference.current.getContext('2d');

            if (this.props.autosave_spacing_type === 0)
            {
                context.fillStyle = '#000000';
                context.fillRect(0, 0, canvas.width, canvas.height);
            }
            else
            {
                context.fillStyle = '#'+this.props.content_spacing_colors[this.props.autosave_spacing_color][0];
                context.fillRect(0, 0, canvas.width, canvas.height);
            }

            if (this.props.autosave_diffuse_image.length === 1)
            {
                // Single stone image
                context.drawImage(this.props.autosave_diffuse_image[0], 0, 0);
            }
            else
            {
                // Create offscreen result canvas
                let canvas_result = this.canvas_autosave_reference.current.cloneNode();
                let context_result = canvas_result.getContext('2d', {willReadFrequently: true});
                canvas_result.width = 648;
                canvas_result.height = 160;
                context_result.globalCompositeOperation = "source-over";

                // Create offscreen diffuse canvas
                let canvas_diffuse = this.canvas_autosave_reference.current.cloneNode();
                let context_diffuse = canvas_diffuse.getContext('2d', {willReadFrequently: true});
                canvas_diffuse.width = 648;
                canvas_diffuse.height = 160;
                context_diffuse.globalCompositeOperation = "source-over";

                // Create offscreen mask canvas
                let canvas_mask = this.canvas_autosave_reference.current.cloneNode();
                let context_mask = canvas_mask.getContext('2d', {willReadFrequently: true});
                canvas_mask.width = 648;
                canvas_mask.height = 160;
                
                for (let i = 0; i < this.props.autosave_diffuse_image.length; i++)
                {
                    // Draw the mixmask
                    context_mask.globalCompositeOperation = "source-over";
                    context_mask.fillStyle = '#000000';
                    context_mask.fillRect(0, 0, canvas_mask.width, canvas_mask.height);

                    context_mask.globalCompositeOperation = "lighter";
                    context_mask.drawImage(this.props.autosave_mixmask_image[i], 0, 0);

                    // Draw the diffuse layer
                    context_diffuse.drawImage(this.props.autosave_diffuse_image[i], 0, 0);

                    // Apply the mask to the diffuse layer
                    let imagedata_mask = context_mask.getImageData(0, 0, canvas_mask.width, canvas_mask.height);
                    let data_mask = imagedata_mask.data;

                    let imagedata_diffuse = context_diffuse.getImageData(0, 0, canvas_diffuse.width, canvas_diffuse.height);
                    let data_diffuse = imagedata_diffuse.data;

                    let j = 0;
                    while (j < data_diffuse.length)
                    {
                        data_diffuse[j+3] = data_mask[j];

                        j += 4;
                    }

                    context_diffuse.putImageData(imagedata_diffuse, 0, 0);

                    // Draw to the result layer
                    context_result.drawImage(canvas_diffuse, 0, 0);
                }

                // Draw result
                context.drawImage(canvas_result, 0, 0);
                
                // Cleanup
                canvas_result = null;
                canvas_diffuse = null;
                canvas_mask = null;
            }

            // Resize immediately
            this.canvas_autosave_resize();

            // When the window is resized, queue up for canvas resize
            window.addEventListener("resize", this.canvas_autosave_resize);
        }
    }

    componentWillUnmount()
    {
        if (this.props.autosave_found === true)
        {
            this.canvas_autosave_reference = null;

            // Remove listener
            window.removeEventListener("resize", this.canvas_autosave_resize);
        }
    }
    
    canvas_autosave_resize()
    {
        let item_canvas = document.getElementById('canvas_autosave_id');
        let height = item_canvas.offsetWidth / (648/160);
        item_canvas.style.height = (height)+"px";

        let item_button = document.getElementById('start_autosave_id');
        item_button.style.marginTop = (height+24)+"px"
    }

    render()
    {
        let design = '';
        if (this.props.autosave_found === true)
        {
            design = (
                <button id="start_autosave_id" onClick={() => this.props.function_configurator_start_autosave()} className="start-button-large-arrow-continue">
                    <span>{this.props.function_translate('intro_continue')} </span>

                    <canvas id="canvas_autosave_id" ref={this.canvas_autosave_reference} className="start-autosave-canvas"></canvas>
                </button>
            );
        }

        return (
            <section className="start-section">
                <div className="container start-container">
                    <div className="row">
                        <div className="start-column col-12 col-sm-8 col-lg-6 col-xl-5">
                            <div className="start-label-highlight">{this.props.function_translate('texturegenerator')}</div>
                            <h1 className="start-title">{this.props.function_translate('intro_headline')}</h1>
                            <p className="start-text">{this.props.function_translate('intro_description')}</p>

                            <button onClick={() => this.props.function_intro_start_existing()}className="start-button-large-arrow"><span>{this.props.function_translate('intro_option1')}</span></button>
                            
                            <button onClick={() => this.props.function_configurator_start_blanco()}className="start-button-large-arrow"><span>{this.props.function_translate('intro_option2')}</span></button>

                            {design}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Start;