import React, { Component } from 'react';

class Canvas extends Component
{
    // Render the canvas when it's mounted
    componentDidMount()
    {
        // Resize immediately
        this.props.function_canvas_resize();
        
        // When the window is resized, queue up for canvas resize
        window.addEventListener("resize", this.props.function_canvas_queue_resize);
    }

    componentWillUnmount()
    {
        window.removeEventListener("resize", this.props.function_canvas_resize);
    }
    
    render()
    {
        let container = 'canvas-container';

        if (this.props.show === false)
        {
            container = 'canvas-container-hide';
        }

        let window_style = {
            height: this.props.window_height+'px'
        };

        /*
        if (this.props.view_mode === 1)
        {
            window_style = {
                height: (this.props.window_height-72)+'px'
            };
        }
        */

        return (
            <div className={container} style={window_style}>
                <canvas className="canvas" ref={this.props.canvas_reference} id="canvas_id" onClick={() => this.props.function_configurator_disable_mobile()}>
                    <div className="canvas-not-supported">
                        Deze website gebruikt functies die niet door uw browser worden ondersteund. Gelieve up browser up te daten, of een alternatief te downloaden via de volgende links:<br/><br/><a href="http://www.google.be/chrome" target="_blank" rel="noreferrer">Google Chrome</a><br/><a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noreferrer">Mozilla Firefox</a>
                    </div>
                </canvas>
            </div>
        );
    }
}

export default Canvas