import React, { Component } from 'react';

// Intro

class Intro extends Component
{
    constructor(props)
    {
        // Props
        super(props);

        // References
        this.canvas_reference = [];
        for (let i = 0; i < this.props.content_intro.length; i++)
        {
            this.canvas_reference.push(React.createRef());
        }
        
        this.canvas_resize = this.canvas_resize.bind(this);
    }
    
    componentDidMount()
    {
        for (let i = 0; i < this.props.content_intro.length; i++)
        {
            // Resize
            let canvas = document.getElementById('canvas_intro_id'+i);
            canvas.width = 648;
            canvas.height = 286;

            // Draw
            let context = this.canvas_reference[i].current.getContext('2d', {willReadFrequently: true});

            //context.fillStyle = '#000000';
            context.fillStyle = '#FF0000';
            context.fillRect(0, 0, canvas.width, canvas.height);
            
            if (this.props.intro_diffuse_image[i].length === 1)
            {
                // Single stone image
                context.drawImage(this.props.intro_diffuse_image[i][0], 0, 0);
            }
            else
            {
                // Create offscreen result canvas
                let canvas_result = this.canvas_reference[0].current.cloneNode();
                let context_result = canvas_result.getContext('2d', {willReadFrequently: true});
                canvas_result.width = 648;
                canvas_result.height = 286;
                context_result.globalCompositeOperation = "source-over";

                // Create offscreen diffuse canvas
                let canvas_diffuse = this.canvas_reference[0].current.cloneNode();
                let context_diffuse = canvas_diffuse.getContext('2d', {willReadFrequently: true});
                canvas_diffuse.width = 648;
                canvas_diffuse.height = 286;
                context_diffuse.globalCompositeOperation = "source-over";
                
                // Create offscreen mask canvas
                let canvas_mask = this.canvas_reference[0].current.cloneNode();
                let context_mask = canvas_mask.getContext('2d', {willReadFrequently: true});
                canvas_mask.width = 648;
                canvas_mask.height = 286;
                
                for (let j = 0; j < this.props.intro_diffuse_image[i].length; j++)
                {
                    // Draw the mixmask
                    context_mask.globalCompositeOperation = "source-over";
                    context_mask.fillStyle = '#000000';
                    context_mask.fillRect(0, 0, canvas_mask.width, canvas_mask.height);

                    context_mask.globalCompositeOperation = "lighter";
                    context_mask.drawImage(this.props.intro_mixmask_image[i][j], 0, 0);

                    // Draw the diffuse layer
                    context_diffuse.drawImage(this.props.intro_diffuse_image[i][j], 0, 0);

                    // Apply the mask to the diffuse layer
                    let imagedata_mask = context_mask.getImageData(0, 0, canvas_mask.width, canvas_mask.height);
                    let data_mask = imagedata_mask.data;

                    let imagedata_diffuse = context_diffuse.getImageData(0, 0, canvas_diffuse.width, canvas_diffuse.height);
                    let data_diffuse = imagedata_diffuse.data;

                    let k = 0;
                    while (k < data_diffuse.length)
                    {
                        data_diffuse[k+3] = data_mask[k];

                        k += 4;
                    }

                    context_diffuse.putImageData(imagedata_diffuse, 0, 0);

                    // Draw to the result layer
                    context_result.drawImage(canvas_diffuse, 0, 0);
                }

                // Single base stone image
                context.drawImage(this.props.intro_diffuse_image[i][0], 0, 0);

                // Draw result
                context.drawImage(canvas_result, 0, 0);
                
                // Cleanup
                canvas_result = null;
                canvas_diffuse = null;
                canvas_mask = null;
            }
        }
        
        // Resize immediately
        this.canvas_resize();

        // When the window is resized, queue up for canvas resize
        window.addEventListener("resize", this.canvas_resize);
    }
    
    componentWillUnmount()
    {
        for (let i = 0; i < this.props.content_intro.length; i++)
        {
            this.canvas_reference[i] = null;
        }

        // Remove listener
        window.removeEventListener("resize", this.canvas_resize);
    }
    
    canvas_resize()
    {
        for (let i = 0; i < this.props.content_intro.length; i++)
        {
            let item = document.getElementById('intro_item_id'+i);
            item.style.height = (item.offsetWidth / (648/286))+"px";
        }
    }
    
    render()
    {
        let line = '';
        if (this.props.configurator_changed === false)
        {
            line = (
                <div className="intro-title">
                    {this.props.function_translate('intro_title1')} {this.props.function_translate('intro_title2')} <span className="intro-title-link" onClick={() => this.props.function_configurator_start_blanco()}>{this.props.function_translate('intro_title3')}</span>
                </div>
            );
        }
        else
        {
            line = (
                <div className="intro-title">
                    {this.props.function_translate('intro_title1')}, <span className="intro-title-link" onClick={() => this.props.function_configurator_start_continue()}>{this.props.function_translate('intro_title4')}</span> {this.props.function_translate('intro_title2')} <span className="intro-title-link" onClick={() => this.props.function_configurator_start_blanco()}>{this.props.function_translate('intro_title3')}</span>
                </div>
            );
        }

        const items = [];
        
        for (let i = 0; i < this.props.content_intro.length; i++)
        {
            // Displayname (bottom right)
            let displayname = '';
            if (typeof this.props.content_intro[i][14+this.props.translation_language_index] !== 'object' && this.props.content_intro[i][14+this.props.translation_language_index] !== null)
            {
                displayname = <div className="intro-item-displayname">{this.props.content_intro[i][14+this.props.translation_language_index]}</div>
            }

            // Label (top left)
            let label = '';
            if (this.props.content_intro[i][16] === true)
            {
                if (typeof this.props.content_intro[i][17+this.props.translation_language_index] !== 'object' && this.props.content_intro[i][17+this.props.translation_language_index] !== null)
                {
                    if (this.props.content_intro[i][19] === false)
                        label = <div className="intro-item-label">{this.props.content_intro[i][17+this.props.translation_language_index]}</div>;
                    else
                        label = <div className="intro-item-label-highlight">{this.props.content_intro[i][17+this.props.translation_language_index]}</div>;
                }
            }
            
            items.push(
                <div className="col-xl-4 col-md-6 col-sm-12" key={i}>
                    <div id={"intro_item_id"+i} className="intro-item" onClick={() => this.props.function_configurator_start(i)}>
                        <canvas className="intro-item-canvas" ref={this.canvas_reference[i]} id={"canvas_intro_id"+i}></canvas>
                        <div className="intro-item-border"></div>

                        {label}
                        {displayname}
                    </div>
                </div>
            );
        }
        
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        {line}
                    </div>
                </div>
                
                <div className="row">
                    {items}
                </div>
            </React.Fragment>
        );
    }
}

export default Intro