import React, { Component } from 'react';
import axios from 'axios';
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

// -----------------------------------------------------------------------

// Popup how to use
class PopupHowtouse extends Component
{
    render()
    {
        const window_style = {
            height: this.props.window_height+'px'
        }

        return (
            <div className="popup" style={window_style}>
                <div className="popup-inner">
                    <div className="popup-close" onClick={() => this.props.function_popup_close()}>
                    </div>

                    <div className="popup-container">
                        <h1 className="popup-title">{this.props.function_translate('popup_how_to_use')}</h1>

                        <p className="popup-text">{this.props.function_translate('popup_how_to_use_intro')}</p>

                        <div className="clearfix">
                            <button className="popup-button" onClick={() => this.props.function_popup_open('message')}><span>{this.props.function_translate('popup_send_us_a_message')}</span></button>
                            <button className="popup-button" onClick={() => this.props.function_popup_open('faq')}><span>{this.props.function_translate('popup_faq')}</span></button>
                        </div>
                        <br/>

                        <p className="popup-text"><span>{this.props.function_translate('popup_how_to_use_1_title')}</span><br/>{this.props.function_translate('popup_how_to_use_1_description')}</p>

                        <p className="popup-text"><span>{this.props.function_translate('popup_how_to_use_2_title')}</span><br/>{this.props.function_translate('popup_how_to_use_2_description')}</p>
                        
                        <p className="popup-text"><span>{this.props.function_translate('popup_how_to_use_3_title')}</span><br/>{this.props.function_translate('popup_how_to_use_3_description')}</p>
                    </div>
                </div>
            </div>
        )
    }
}

// Popup message
class PopupMessage extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            form_name: '',
            form_client: this.props.content_form_clients[0][this.props.translation_language_index],
            form_email: '',
            form_phone: '',
            form_description: '',
            form_terms: false,
            form_accept: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event)
    {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        
        this.setState({
            [name]: value
        });
    }
    
    handleSubmit(event)
    {
        const post_form_contact = async () => {
            try
            {
                const data = {
                    domain: this.props.ip,
                    name: this.state.form_name,
                    client: this.state.form_client,
                    email: this.state.form_email,
                    phone: this.state.form_phone,
                    description: this.state.form_description,
                    terms: this.state.form_terms,
                    accept: this.state.form_accept
                }

                await axios.post(this.props.ip+'/backend/api/post_form_contact', data);

                this.props.function_popup_open('message_sent');
            }
            
            catch(error)
            {
                console.log(error);
            }
        }

        post_form_contact();

        event.preventDefault();
    }

    render()
    {
        const form_client_dropdown = [];

        for (let i = 0; i < this.props.content_form_clients.length; i++)
        {
            let title = this.props.content_form_clients[i][this.props.translation_language_index];

            form_client_dropdown.push(
                <option key={i} value={title}>{title}</option>
            );
        }

        let form_terms_class = 'form-checkbox';
        if (this.state.form_terms === true)
            form_terms_class = 'form-checkbox-active';

        let form_privacy_policy;
        if (this.props.translation_language_index === 0)
            form_privacy_policy = 'https://www.ebema.be/nl-BE/privacy';
        else
            form_privacy_policy = 'https://www.ebema.be/fr-BE/protection-vie-privee';

        const window_style = {
            height: this.props.window_height+'px'
        }

        return (
            <div className="popup" style={window_style}>
                <div className="popup-inner">
                    <div className="popup-close" onClick={() => this.props.function_popup_close()}>
                    </div>

                    <div className="popup-container">
                        <h1 className="popup-title">{this.props.function_translate('popup_send_us_a_message')}</h1>

                        <p className="popup-text">{this.props.function_translate('popup_send_us_a_message_intro')}</p>

                        <form onSubmit={this.handleSubmit}>
                            <input
                                className = "form-singleline"
                                name= "form_name"
                                type = "text"
                                value = {this.state.form_name}
                                onChange = {this.handleInputChange}
                                placeholder = {this.props.function_translate('form_name')+' *'}
                                required />

                            <select
                                className = "form-singleline-dropdown"
                                name = "form_client"
                                value = {this.state.form_client}
                                onChange = {this.handleInputChange}>
                                
                                {form_client_dropdown}
                            </select>

                            <input
                                className = "form-singleline"
                                name = "form_email"
                                type = "email"
                                value = {this.state.form_email}
                                onChange = {this.handleInputChange}
                                placeholder = {this.props.function_translate('form_email')+' *'}
                                required />

                            <input
                                className = "form-singleline"
                                name = "form_phone"
                                type = "text"
                                value = {this.state.form_phone}
                                onChange = {this.handleInputChange}
                                placeholder = {this.props.function_translate('form_phone')} />

                            <textarea
                                className = "form-multiline"
                                name = "form_description"
                                value = {this.state.form_description}
                                onChange = {this.handleInputChange}
                                placeholder = {this.props.function_translate('form_message_description')} />

                            <div className="form-checkbox-container">
                                <label className={form_terms_class}>
                                    <input
                                        name = "form_terms"
                                        type = "checkbox"
                                        checked = {this.state.form_terms}
                                        onChange = {this.handleInputChange} 
                                        required />
                                    <span className="form-text">{this.props.function_translate('form_privacy1')} <a className="form-hyperlink" href={form_privacy_policy} target="_blank" rel="noreferrer">{this.props.function_translate('form_privacy2')}</a> *</span>
                                </label>
                            </div>

                            <input
                                className = "form-accept"
                                type = "checkbox"
                                name = "form_accept"
                                value = {this.state.form_accept}
                                onChange = {this.handleInputChange}
                                />

                            <button type="submit" className="form-button"><span>{this.props.function_translate('form_send')}</span></button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

// Popup message sent
class PopupMessageSent extends Component
{
    render()
    {
        const window_style = {
            height: this.props.window_height+'px'
        }

        return (
            <div className="popup" style={window_style}>
                <div className="popup-inner">
                    <div className="popup-close" onClick={() => this.props.function_popup_close()}>
                    </div>

                    <div className="popup-container">
                        <h1 className="popup-title">{this.props.function_translate('popup_send_us_a_message_sent')}</h1>

                        <p className="popup-text">{this.props.function_translate('popup_send_us_a_message_response')}</p>
                    </div>
                </div>
            </div>
        )
    }
}

// Popup FAQ
class PopupFAQ extends Component
{
    render()
    {
        const window_style = {
            height: this.props.window_height+'px'
        }

        return (
            <div className="popup" style={window_style}>
                <div className="popup-inner">
                    <div className="popup-close" onClick={() => this.props.function_popup_close()}>
                    </div>

                    <div className="popup-container">
                        <h1 className="popup-title">{this.props.function_translate('popup_faq')}</h1>

                        <p className="popup-text"><span>{this.props.function_translate('popup_faq_1_title')}</span><br/>{this.props.function_translate('popup_faq_1_description')}</p>

                        <p className="popup-text"><span>{this.props.function_translate('popup_faq_2_title')}</span><br/>{this.props.function_translate('popup_faq_2_description')}</p>

                        <p className="popup-text"><span>{this.props.function_translate('popup_faq_3_title')}</span><br/>{this.props.function_translate('popup_faq_3_description')}</p>
                    </div>
                </div>
            </div>
        )
    }
}

// Popup save
class PopupSave extends Component
{
    render()
    {
        const window_style = {
            height: this.props.window_height+'px'
        }

        return (
            <div className="popup" style={window_style}>
                <div className="popup-inner">
                    <div className="popup-close" onClick={() => this.props.function_popup_close()}>
                    </div>

                    <div className="popup-container">
                        <h1 className="popup-title">{this.props.function_translate('popup_saved_title')}</h1>

                        <p className="popup-text">{this.props.function_translate('popup_saved_description')} <a className="form-hyperlink" href={this.props.function_translate('popup_saved_link')} target="_blank" rel="noreferrer">{this.props.function_translate('popup_saved_view')}</a></p>
                    </div>
                </div>
            </div>
        )
    }
}

// Popup cannot save
class PopupCannotSave extends Component
{
    render()
    {
        const window_style = {
            height: this.props.window_height+'px'
        }

        return (
            <div className="popup" style={window_style}>
                <div className="popup-inner">
                    <div className="popup-close" onClick={() => this.props.function_popup_close()}>
                    </div>

                    <div className="popup-container">
                        <p className="popup-text">{this.props.function_translate('popup_cannot_save_description')}</p>
                    </div>
                </div>
            </div>
        )
    }
}

// Popup cannot download
class PopupCannotDownload extends Component
{
    render()
    {
        const window_style = {
            height: this.props.window_height+'px'
        }

        return (
            <div className="popup" style={window_style}>
                <div className="popup-inner">
                    <div className="popup-close" onClick={() => this.props.function_popup_close()}>
                    </div>

                    <div className="popup-container">
                        <p className="popup-text">{this.props.function_translate('popup_cannot_download_description')}</p>
                    </div>
                </div>
            </div>
        )
    }
}

// Popup downloads
class PopupDownloads extends Component
{
    render()
    {
        let visualisation = '';
        
        if (this.props.view_mode === 1)
        {
            if (this.props.photo_loaded === true)
            {
                visualisation = (
                    <React.Fragment>
                        <div className="clearfix">
                            <button className="popup-button-alt" onClick={() => this.props.function_export_pdf(1, 'visualisation')}><span>{this.props.function_translate('popup_downloads_design_visualisation')}</span></button>
                        </div>
                        <br/>
                    </React.Fragment>
                );
            }
            else
            {
                visualisation = (
                    <React.Fragment>
                        <div className="loading-rotate-intro"></div>
                        <br/>
                    </React.Fragment>
                );
            }
        }
        else
        {
            visualisation = (
                <React.Fragment>
                    <div className="popup-text">{this.props.function_translate('popup_downloads_visualisation1')} <div className="popup-text-hyperlink" onClick={() => this.props.function_view_mode(1)}>{this.props.function_translate('popup_downloads_visualisation2')}</div></div>
                    <br/>
                </React.Fragment>
            );
        }

        const window_style = {
            height: this.props.window_height+'px'
        }

        return (
            <div className="popup" style={window_style}>
                <div className="popup-inner">
                    <div className="popup-close" onClick={() => this.props.function_popup_close()}>
                    </div>

                    <div className="popup-container">
                        <h1 className="popup-title">{this.props.function_translate('popup_downloads_title')}</h1>

                        <p className="popup-text">{this.props.function_translate('popup_downloads_direct')}</p>

                        <div className="clearfix">
                            <button className="popup-button-alt" onClick={() => this.props.function_export_pdf(0, 'texture')}><span>{this.props.function_translate('popup_downloads_design_pdf')}</span></button>
                        </div>
                        <br/>

                        {visualisation}

                        <p className="popup-text">{this.props.function_translate('popup_downloads_full')}</p>

                        <div className="clearfix">
                            <button className="popup-button-alt" onClick={() => this.props.function_popup_open('task')}><span>{this.props.function_translate('popup_downloads_3d')}</span></button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// Popup task
class PopupTask extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            form_firstname: '',
            form_surname: '',
            form_email: '',
            form_client: this.props.content_form_clients[0][this.props.translation_language_index],
            form_companyname: '',
            form_sketchup: false,
            form_revit: false,
            form_terms: false,
            form_subscribe: false,
            form_accept: false,
        };
        
        /*
        this.state = {
            form_firstname: 'Guus',
            form_surname: 'Thissen',
            form_email: 'guus@thissen.ai',
            form_client: this.props.content_form_clients[0][this.props.translation_language_index],
            form_companyname: 'Test',
            form_sketchup: false,
            form_revit: false,
            form_terms: true,
            form_subscribe: false,
            form_accept: false,
        };
        */

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event)
    {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event)
    {
        const post_form_task = async () => {
            try
            {
                let querystring = this.props.function_configurator_get_querystring();

                let companyname = this.state.form_companyname;
                if (companyname === '')
                {
                    companyname = '-'
                }

                const data = {
                    domain: this.props.ip,
                    firstname: this.state.form_firstname,
                    surname: this.state.form_surname,
                    email: this.state.form_email,
                    client: this.state.form_client,
                    companyname: companyname,
                    configuration: querystring,
                    sketchup: this.state.form_sketchup,
                    revit: this.state.form_revit,
                    terms: this.state.form_terms,
                    subscribe: this.state.form_subscribe,
                    accept: this.state.form_accept
                }

                await axios.post(this.props.ip+'/backend/api/post_form_task', data);

                this.props.function_message_post_querystring(false, false, true);
                this.props.function_popup_open('task_complete');
            }

            catch(error)
            {
                console.log(error);
            }
        }

        post_form_task();

        event.preventDefault();
    }
    
    render()
    {
        const form_client_dropdown = [];

        for (let i = 0; i < this.props.content_form_clients.length; i++)
        {
            let title = this.props.content_form_clients[i][this.props.translation_language_index];

            form_client_dropdown.push(
                <option key={i} value={title}>{title}</option>
            );
        }

        let form_terms_class = 'form-checkbox';
        if (this.state.form_terms === true)
            form_terms_class = 'form-checkbox-active';
        
        let form_privacy_policy;
        if (this.props.translation_language_index === 0)
            form_privacy_policy = 'https://www.ebema.be/nl-BE/privacy';
        else
            form_privacy_policy = 'https://www.ebema.be/fr-BE/protection-vie-privee';
        
        let form_subscribe_class = 'form-checkbox';
        if (this.state.form_subscribe === true)
            form_subscribe_class = 'form-checkbox-active';
        
        //let form_sketchup_class = 'form-checkbox';
        //if (this.state.form_sketchup === true)
        //    form_sketchup_class = 'form-checkbox-active';

        //let form_revit_class = 'form-checkbox';
        //if (this.state.form_revit === true)
        //    form_revit_class = 'form-checkbox-active';

        /*
        <p className="popup-text-form">{this.props.function_translate('popup_downloads_sketchup_info')}</p>

        <div className="form-checkbox-container">
            <label className={form_sketchup_class}>
                <input
                    name = "form_sketchup"
                    type = "checkbox"
                    checked = {this.state.form_sketchup}
                    onChange = {this.handleInputChange} />
                    <span className="form-text">{this.props.function_translate('popup_downloads_sketchup')}</span>
            </label>
        </div>
        */

        /*
        <div className="form-checkbox-container">
            <label className={form_revit_class}>
                <input
                    name = "form_revit"
                    type = "checkbox"
                    checked = {this.state.form_revit}
                    onChange = {this.handleInputChange} />
                    <span className="form-text">{this.props.function_translate('form_privacy1')}</span>
            </label>
        </div>
        */

        const window_style = {
            height: this.props.window_height+'px'
        }

        return (
            <div className="popup" style={window_style}>
                <div className="popup-inner">
                    <div className="popup-close" onClick={() => this.props.function_popup_close()}>
                    </div>

                    <div className="popup-container">
                        <h1 className="popup-title">{this.props.function_translate('popup_downloads_title_3d')}</h1>

                        <p className="popup-text">{this.props.function_translate('popup_downloads_information')}</p>
                    
                        <form onSubmit={this.handleSubmit}>
                            <input
                                className = "form-singleline-half-left"
                                name = "form_firstname"
                                type = "text"
                                value = {this.state.form_firstname}
                                onChange = {this.handleInputChange} 
                                placeholder = {this.props.function_translate('form_firstname')+' *'} 
                                required />

                            <input
                                className = "form-singleline-half-right"
                                name = "form_surname"
                                type = "text"
                                value = {this.state.form_surname}
                                onChange = {this.handleInputChange}
                                placeholder = {this.props.function_translate('form_surname')+' *'} 
                                required />

                            <input
                                className = "form-singleline"
                                name = "form_email"
                                type = "email"
                                value = {this.state.form_email}
                                onChange = {this.handleInputChange}
                                placeholder = {this.props.function_translate('form_email')+' *'} 
                                required />

                            <select
                                className = "form-singleline-dropdown"
                                name = "form_client"
                                value = {this.state.form_client}
                                onChange = {this.handleInputChange}>
                                
                                {form_client_dropdown}
                            </select>

                            <input
                                className = "form-singleline"
                                name = "form_companyname"
                                type = "text"
                                value = {this.state.form_companyname}
                                onChange = {this.handleInputChange}
                                placeholder = {this.props.function_translate('form_company')} />

                            <div className="form-checkbox-container">
                                <label className={form_terms_class}>
                                    <input
                                        name = "form_terms"
                                        type = "checkbox"
                                        checked = {this.state.form_terms}
                                        onChange = {this.handleInputChange} 
                                        required />
                                    <span className="form-text">{this.props.function_translate('form_privacy1')} <a className="form-hyperlink" href={form_privacy_policy} target="_blank" rel="noreferrer">{this.props.function_translate('form_privacy2')}</a> *</span>
                                </label>
                            </div>

                            <div className="form-checkbox-container">
                                <label className={form_subscribe_class}>
                                    <input
                                        name = "form_subscribe"
                                        type = "checkbox"
                                        checked = {this.state.form_subscribe}
                                        onChange = {this.handleInputChange} />
                                    <span className="form-text">{this.props.function_translate('form_newsletter')}</span>
                                </label>
                            </div>

                            <input
                                className = "form-accept"
                                type = "checkbox"
                                name = "form_accept"
                                value = {this.state.form_accept}
                                onChange = {this.handleInputChange}
                                />

                            <button type="submit" className="form-button"><span>{this.props.function_translate('form_send')}</span></button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

// Popup task complete
class PopupTaskComplete extends Component
{
    render()
    {
        const window_style = {
            height: this.props.window_height+'px'
        }

        return (
            <div className="popup" style={window_style}>
                <div className="popup-inner">
                    <div className="popup-close" onClick={() => this.props.function_popup_close()}>
                    </div>

                    <div className="popup-container">
                        <h1 className="popup-title">{this.props.function_translate('popup_downloads_3d_complete')}</h1>

                        <p className="popup-text">{this.props.function_translate('popup_downloads_3d_complete_info')}</p>

                        <p className="popup-text">{this.props.function_translate('popup_downloads_3d_complete_team')}</p>
                    </div>
                </div>
            </div>
        );
    }
}

// Popup productinfo
class PopupProductInfo extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            form_type: '0'
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event)
    {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event)
    {
        event.preventDefault();
    }

    render()
    {
        const form_type_dropdown = [];
        form_type_dropdown.push(
            <option key={0} value={0}>{this.props.function_translate('form_stones')}</option>,
            <option key={1} value={1}>{this.props.function_translate('form_strips')}</option>
        );

        let language = 'NL';
        if (this.props.translation_language_index === 1)
            language = 'FR';

        const downloads = [];
        
        let key = -1;
        for (let i = 0; i < this.props.content_stone_types.length; i++)
        {
            // Check if this stone is in the current collection
            if (this.props.content_stone_types[i][1] === this.props.content_stone_collections[this.props.configurator_stone_collection][0])
            {
                if (this.props.configurator_stone_multi[i] > 0)
                {
                    if (this.state.form_type === '0')
                    {
                        // Stones
                        key++;
                        downloads.push(
                            <React.Fragment key={key}>
                                <div className="clearfix">
                                    <button className="popup-button-alt" onClick={() => window.open('https://apps.ebema.com/api/docgenapi/api/bestektekst/'+language+'/'+this.props.content_stone_types[i][6], '_blank')}>
                                        <span>{this.props.content_stone_types[i][2+this.props.translation_language_index]+' '+this.props.function_translate('popup_downloads_stone')}</span>
                                    </button>
                                </div>
                                <br/>
                            </React.Fragment>
                        );
                    }
                    else
                    {
                        // Strips
                        key++;
                        downloads.push(
                            <React.Fragment key={key}>
                                <div className="clearfix">
                                    <button className="popup-button-alt" onClick={() => window.open('https://apps.ebema.com/api/docgenapi/api/bestektekst/'+language+'/'+this.props.content_stone_types[i][4], '_blank')}>
                                        <span>{this.props.content_stone_types[i][2+this.props.translation_language_index]+' '+this.props.function_translate('popup_downloads_strip')}</span>
                                    </button>
                                </div>
                                <br/>
                            </React.Fragment>
                        );

                        key++;
                        downloads.push(
                            <React.Fragment key={key}>
                                <div className="clearfix">
                                    <button className="popup-button-alt" onClick={() => window.open('https://apps.ebema.com/api/docgenapi/api/bestektekst/'+language+'/'+this.props.content_stone_types[i][5], '_blank')}>
                                        <span>{this.props.content_stone_types[i][2+this.props.translation_language_index]+' '+this.props.function_translate('popup_downloads_strip_corner')}</span>
                                    </button>
                                </div>
                                <br/>
                            </React.Fragment>
                        );
                    }
                }
            }
        }

        const window_style = {
            height: this.props.window_height+'px'
        }
        
        return (
            <div className="popup" style={window_style}>
                <div className="popup-inner">
                    <div className="popup-close" onClick={() => this.props.function_popup_close()}>
                    </div>

                    <div className="popup-container">
                        <h1 className="popup-title">{this.props.function_translate('function_download_pdf')}</h1>

                        <p className="popup-text" style={{'marginBottom': '0px'}}>{this.props.function_translate('function_download_pdf_info')}</p>
                        
                        <form onSubmit={this.handleSubmit}>
                            <select
                                className = "form-singleline-dropdown"
                                name = "form_type"
                                value = {this.state.form_type}
                                onChange = {this.handleInputChange}>
                                
                                {form_type_dropdown}
                            </select>
                        </form>
                        <br/>

                        {downloads}
                    </div>
                </div>
            </div>
        );
    }
}

// Popup in development
class PopupInDevelopment extends Component
{
    render()
    {
        const window_style = {
            height: this.props.window_height+'px'
        }

        return (
            <div className="popup" style={window_style}>
                <div className="popup-inner">
                    <div className="popup-close" onClick={() => this.props.function_popup_close()}>
                    </div>

                    <div className="popup-container">
                        <h1 className="popup-title">{this.props.function_translate('popup_in_development')}</h1>
                    </div>
                </div>
            </div>
        )
    }
}

// -----------------------------------------------------------------------
                   
// Popup

class Popup extends Component
{
    render()
    {
        switch(this.props.popup_current)
        {
            case 'howtouse':        return (
                                        <PopupHowtouse
                                            window_height={this.props.window_height}
                                            function_translate={this.props.function_translate}
                                            function_popup_open={this.props.function_popup_open}
                                            function_popup_close={this.props.function_popup_close} />
                                    );

            case 'message':         return (
                                        <PopupMessage 
                                            ip={this.props.ip}
                                            translation_language_index={this.props.translation_language_index}
                                            window_height={this.props.window_height}
                                            content_form_clients={this.props.content_form_clients}
                                            function_translate={this.props.function_translate}
                                            function_popup_open={this.props.function_popup_open}
                                            function_popup_close={this.props.function_popup_close} />
                                    );

            case 'message_sent':    return (
                                        <PopupMessageSent
                                            window_height={this.props.window_height}
                                            function_translate={this.props.function_translate}
                                            function_popup_close={this.props.function_popup_close} />
                                    );

            case 'faq':             return (
                                        <PopupFAQ
                                            window_height={this.props.window_height}
                                            function_translate={this.props.function_translate}
                                            function_popup_close={this.props.function_popup_close} />
                                    );

            case 'save':            return (
                                        <PopupSave
                                            window_height={this.props.window_height}
                                            function_translate={this.props.function_translate}
                                            function_popup_close={this.props.function_popup_close} />
                                    );

            case 'cannot_save':     return (
                                        <PopupCannotSave
                                            window_height={this.props.window_height}
                                            function_translate={this.props.function_translate}
                                            function_popup_close={this.props.function_popup_close} />
                                    );

            case 'cannot_download': return (
                                        <PopupCannotDownload
                                            window_height={this.props.window_height}
                                            function_translate={this.props.function_translate}
                                            function_popup_close={this.props.function_popup_close} />
                                    );

            case 'downloads':       return (
                                        <PopupDownloads
                                            translation_language_index={this.props.translation_language_index}
                                            content_form_clients={this.props.content_form_clients}
                                            photo_loaded={this.props.photo_loaded}
                                            view_mode={this.props.view_mode}
                                            window_height={this.props.window_height}
                                            function_translate={this.props.function_translate}
                                            function_view_mode={this.props.function_view_mode}
                                            function_popup_open={this.props.function_popup_open}
                                            function_popup_close={this.props.function_popup_close}
                                            function_form_download={this.props.function_form_download}
                                            function_export_pdf={this.props.function_export_pdf} />
                                    );

            case 'task':            return (
                                        <PopupTask
                                            ip={this.props.ip}
                                            translation_language_index={this.props.translation_language_index}
                                            content_form_clients={this.props.content_form_clients}
                                            window_height={this.props.window_height}
                                            function_translate={this.props.function_translate}
                                            function_configurator_get_querystring={this.props.function_configurator_get_querystring}
                                            function_popup_open={this.props.function_popup_open}
                                            function_popup_close={this.props.function_popup_close}
                                            function_message_post_querystring={this.props.function_message_post_querystring} />
                                    );

            case 'task_complete':   return (
                                        <PopupTaskComplete
                                            window_height={this.props.window_height}
                                            function_translate={this.props.function_translate}
                                            function_popup_close={this.props.function_popup_close} />
                                    );
            
            case 'productinfo':     return (
                                        <PopupProductInfo
                                            translation_language_index={this.props.translation_language_index}
                                            content_stone_collections={this.props.content_stone_collections}
                                            content_stone_types={this.props.content_stone_types}
                                            configurator_stone_collection={this.props.configurator_stone_collection}
                                            configurator_stone_multi={this.props.configurator_stone_multi}
                                            window_height={this.props.window_height}
                                            function_translate={this.props.function_translate}
                                            function_popup_close={this.props.function_popup_close} />
                                    );

            case 'in_development':  return (
                                        <PopupInDevelopment
                                            window_height={this.props.window_height}
                                            function_translate={this.props.function_translate}
                                            function_popup_close={this.props.function_popup_close} />
                                    );
        
            default:                break;
        }
    }
}

export default Popup