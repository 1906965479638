import React, { Component } from 'react';
import image_check from "../image/icon-check.svg";

// Dropdown item image
function DropdownItemImage(backgroundimageurl)
{
    let style = {
        backgroundImage: backgroundimageurl
    };

    return <div className="configurator-dropdown-item-image" style={style}></div>
}

// Dropdown item color
function DropdownItemColor(backgroundcolor)
{
    let style = {
        backgroundColor: backgroundcolor
    };

    return <div className='configurator-dropdown-item-color' style={style}></div>
}

// Dropdown item border
function DropdownItemBorder(show)
{
    if (show === true)
    {
        return <div className="configurator-dropdown-item-border-selected">
        </div>; 
    }
    else
    {
        return <div className="configurator-dropdown-item-border">
        </div>;
    }
}

// Dropdown item label
function DropdownItemLabel(checkmark, str)
{
    return (
        <div className="configurator-dropdown-item-label">
            {checkmark &&
                <img src={image_check} alt="checkmark" className="configurator-dropdown-item-checkmark"/>
            }

            {str}
        </div>
    )
}

// Dropdown item label multi
function DropdownItemLabelMulti(percentage, str)
{
    if (percentage > 0)
    {
        return (
            <div className="configurator-dropdown-item-multi-text">
                {str}<br/><span style={{color: '#da291c'}}>{percentage}%</span> 
            </div>
        )
    }
    else
    {
        return (
            <div className="configurator-dropdown-item-multi-text">
                {str}<br/><span style={{color: '#da291c', opacity: 0.25}}>0%</span>
            </div>
        )
    }
}

// Dropdown item info
function DropdownItemInfo(str)
{
    return (
        <div className="configurator-dropdown-content-info">
            {str}
        </div>
    )
}

// -----------------------------------------------------------------------

// Back button

class ConfiguratorBackButton extends Component
{
    render()
    {
        return (
            <div className="configurator-backbutton" onClick={() => this.props.function_view_back_to_intro_items()}>
            </div>
        )
    }
}

// -----------------------------------------------------------------------

// Title

class ConfiguratorTitle extends Component
{
    render()
    {
        return (
            <div className="configurator-title">
                {this.props.function_translate('intro_headline')}
            </div>
        )
    }
}

// -----------------------------------------------------------------------

// Dropdown stone collections

class ConfiguratorDropdownCollection extends Component
{
    render()
    {
        if (this.props.content_stone_collections.length > 0)
        {
            // Dropdown
            const dropdown = [];
            for (let i = 0; i < this.props.content_stone_collections.length; i++)
            {
                dropdown.push(
                    <div className="configurator-dropdown-content-items clearfix" key={i}>
                        <button className="configurator-dropdown-item" onClick={() => this.props.function_configurator_stone_collection(i)}>
                            {DropdownItemImage('url(../texture/preview/'+this.props.precompute_stone_name+'_standard_000000_halfsteensverband_h_preview.jpg)')}
                            {DropdownItemBorder(this.props.configurator_stone_collecption === i)}
                            {DropdownItemLabel(this.props.configurator_stone_collection === i, this.props.content_stone_collections[i][2+this.props.translation_language_index])}
                        </button>
                    </div>
                );
            }

            // Mobile button
            let mobile = '';
            if (this.props.configurator_mobile === null)
            {
                mobile = (
                    <div className="configurator-dropdown-mobile">
                        <button className="configurator-dropdown-button" onClick={() => this.props.function_configurator_enable_mobile('stone-collection')}>
                            <div className="configurator-dropdown-button-content">
                                <div className="configurator-dropdown-description">{this.props.function_translate('configurator_collection')}</div>
                                {this.props.content_stone_collections[this.props.configurator_stone_collection][2+this.props.translation_language_index]}
                            </div>
                        </button>
                    </div>
                );
            }
            else
            {
                // Mobile dropdown
                if (this.props.configurator_mobile === 'stone-collection')
                {
                    let style = { left: '32px' };
                    if (this.props.view_mode === 1)
                        style = { left: '16px' };

                    mobile = (
                        <div className="configurator-dropdown-content-mobile" style={style}>
                            <div className="configurator-dropdown-back" onClick={() => this.props.function_configurator_disable_mobile()}><div className="configurator-dropdown-content-mobile-icon"></div>{this.props.function_translate('configurator_close')}</div>
                            <div className="configurator-dropdown-item-mobile-title">{this.props.function_translate('configurator_collection')}</div>
                            {DropdownItemInfo(this.props.function_translate('stone_collection_description'))}
                            {dropdown}
                        </div>
                    );
                }
            }

            return (
                <React.Fragment>
                    <div className="d-none d-sm-block">
                        <div className="configurator-dropdown">
                            <button className="configurator-dropdown-button" id="button-stone-collection">
                                <div className="configurator-dropdown-button-content">
                                    <div className="configurator-dropdown-description">{this.props.function_translate('configurator_collection')}</div>
                                    {this.props.content_stone_collections[this.props.configurator_stone_collection][2+this.props.translation_language_index]}
                                </div>
                            </button>
                            <div className="configurator-dropdown-content">
                                {DropdownItemInfo(this.props.function_translate('stone_collection_description'))}
                                {dropdown}
                            </div>
                        </div>
                    </div>
                    <div className="d-block d-sm-none">
                        {mobile}
                    </div>
                </React.Fragment>
            );
        }
        else
        {
            return (
                <div className="configurator-dropdown">
                    <button className="configurator-dropdown-button">
                    </button>
                    <div className="configurator-dropdown-content">
                    </div>
                </div>
            )
        }
    }
}

// -----------------------------------------------------------------------

// Dropdown stone types

class ConfiguratorDropdownStone extends Component
{
    render()
    {
        if (this.props.content_stone_collections.length > 0
        && this.props.content_stone_types.length > 0)
        {  
            // Dropdown
            let dropdown = [];

            let total = 0;
            for (let i = 0; i < this.props.content_stone_types.length; i++)
            {
                total += this.props.configurator_stone_multi[i];
            }
            if (total === 0)
            {
                total = 1;
            }

            for (let i = 0; i < this.props.content_stone_types.length; i++)
            {
                // Check if this stone is in the current collection
                if (this.props.content_stone_types[i][1] === this.props.content_stone_collections[this.props.configurator_stone_collection][0])
                {
                    let button_decrease, counter;
                    if (this.props.configurator_stone_multi[i] > 0)
                    {
                        button_decrease = <button onClick={() => this.props.function_configurator_stone_select_multi_decrease(i)}className="configurator-dropdown-item-multi-button"><span>-</span></button>

                        counter = <div className="configurator-dropdown-item-multi-counter">{this.props.configurator_stone_multi[i]}</div>;
                    }
                    else
                    {
                        button_decrease = <div className="configurator-dropdown-item-multi-button-placeholder"><span>-</span></div>

                        counter = <div className="configurator-dropdown-item-multi-counter-placeholder">{this.props.configurator_stone_multi[i]}</div>;
                    }

                    let button_increase;
                    if (this.props.configurator_stone_multi[i] < 100)
                    {
                        button_increase = <button onClick={() => this.props.function_configurator_stone_select_multi_increase(i)} className="configurator-dropdown-item-multi-button"><span>+</span></button>
                    }
                    else
                    {
                        button_increase = <div className="configurator-dropdown-item-multi-button-placeholder"><span>+</span></div>
                    }
                
                    dropdown.push(
                        <div className="configurator-dropdown-content-items clearfix" key={i}>
                            <div className="configurator-dropdown-item-multi">
                                {DropdownItemImage('url(../texture/option/'+this.props.content_stone_collections[this.props.configurator_stone_collection][1]+'_'+this.props.content_stone_types[i][0]+'_option.jpg)')}

                                <div className="configurator-dropdown-item-multi-label">
                                    {button_increase}
                                    {counter}
                                    {button_decrease}
                                    {DropdownItemLabelMulti(Math.round((this.props.configurator_stone_multi[i]/total)*100), this.props.content_stone_types[i][3])}
                                </div>
                            </div>
                        </div>
                    );
                }
            }

            // Mobile button
            let mobile = '';
            if (this.props.configurator_mobile === null)
            {
                mobile = (
                    <div className="configurator-dropdown-mobile">
                        <button className="configurator-dropdown-button" onClick={() => this.props.function_configurator_enable_mobile('stone-type')}>
                            <div className="configurator-dropdown-button-content">
                                <div className="configurator-dropdown-description">{this.props.function_translate('configurator_stone')}</div>
                                {this.props.function_translate('stone_multi')}
                            </div>
                        </button>
                    </div>
                );
            }
            else
            {
                // Mobile dropdown
                if (this.props.configurator_mobile === 'stone-type')
                {
                    let style = { left: '32px' };
                    if (this.props.view_mode === 1)
                        style = { left: '16px' };

                    mobile = (
                        <div className="configurator-dropdown-content-mobile" style={style}>
                            <div className="configurator-dropdown-back" onClick={() => this.props.function_configurator_disable_mobile()}><div className="configurator-dropdown-content-mobile-icon"></div>{this.props.function_translate('configurator_close')}</div>
                            <div className="configurator-dropdown-item-mobile-title">{this.props.function_translate('configurator_stone')}</div>
                            {DropdownItemInfo(this.props.function_translate('stone_multi_info'))}
                            {dropdown}
                        </div>
                    );
                }
            }
            
            return (
                <React.Fragment>
                    <div className="d-none d-sm-block">
                        <div className="configurator-dropdown">
                            <button className="configurator-dropdown-button" id="button-stone-type">
                                <div className="configurator-dropdown-button-content">
                                    <div className="configurator-dropdown-description">{this.props.function_translate('configurator_stone')}</div>
                                    {this.props.function_translate('stone_multi')}
                                </div>
                            </button>
                            <div className="configurator-dropdown-content">
                                {DropdownItemInfo(this.props.function_translate('stone_multi_info'))}
                                {dropdown}
                            </div>
                        </div>
                    </div>
                    <div className="d-block d-sm-none">
                        {mobile}
                    </div>
                </React.Fragment>
            );
        }
        else
        {
            return (
                <div className="configurator-dropdown">
                    <button className="configurator-dropdown-button">
                    </button>
                    <div className="configurator-dropdown-content">
                    </div>
                </div>
            )
        }
    }
}

// -----------------------------------------------------------------------

// Dropdown spacing types

class ConfiguratorDropdownSpacingType extends Component
{
    render()
    {
        if (this.props.content_spacing_types.length > 0
        && this.props.precompute_stone_name !== undefined)
        {
            // Dropdown
            const dropdown = [];
            for (let i = 0; i < this.props.content_spacing_types.length; i++)
            {
                dropdown.push(
                    <div className="configurator-dropdown-content-items clearfix" key={i}>
                        <button className="configurator-dropdown-item" onClick={() => this.props.function_configurator_spacing_type(i)}>
                            {DropdownItemImage('url(../texture/preview/'+this.props.precompute_stone_name+'_'+this.props.content_spacing_types[i][0]+'_000000_halfsteensverband_h_preview.jpg)')}

                            {DropdownItemBorder(this.props.configurator_spacing_type === i)}
                            {DropdownItemLabel(this.props.configurator_spacing_type === i, this.props.content_spacing_types[i][1+this.props.translation_language_index])}
                        </button>
                    </div>
                );
            }

            // Mobile button
            let mobile = '';
            if (this.props.configurator_mobile === null)
            {
                mobile = (
                    <div className="configurator-dropdown-mobile">
                        <button className="configurator-dropdown-button" onClick={() => this.props.function_configurator_enable_mobile('spacing-type')}>
                            <div className="configurator-dropdown-button-content">
                                <div className="configurator-dropdown-description">{this.props.function_translate('configurator_spacing')}</div>
                                {this.props.content_spacing_types[this.props.configurator_spacing_type][1+this.props.translation_language_index]}
                            </div>
                        </button>
                    </div>
                );
            }
            else
            {
                // Mobile dropdown
                if (this.props.configurator_mobile === 'spacing-type')
                {
                    let style = { left: '32px' };
                    if (this.props.view_mode === 1)
                        style = { left: '16px' };

                    mobile = (
                        <div className="configurator-dropdown-content-mobile" style={style}>
                            <div className="configurator-dropdown-back" onClick={() => this.props.function_configurator_disable_mobile()}><div className="configurator-dropdown-content-mobile-icon"></div>{this.props.function_translate('configurator_close')}</div>
                            <div className="configurator-dropdown-item-mobile-title">{this.props.function_translate('configurator_spacing')}</div>
                            {dropdown}
                        </div>
                    );
                }
            }

            return (
                <React.Fragment>
                    <div className="d-none d-sm-block">
                        <div className="configurator-dropdown">
                            <button className="configurator-dropdown-button" id="button-spacing-type">
                                <div className="configurator-dropdown-button-content">
                                    <div className="configurator-dropdown-description">{this.props.function_translate('configurator_spacing')}</div>
                                    {this.props.content_spacing_types[this.props.configurator_spacing_type][1+this.props.translation_language_index]}
                                </div>
                            </button>
                            <div className="configurator-dropdown-content">
                                {dropdown}
                            </div>
                        </div>
                    </div>
                    <div className="d-block d-sm-none">
                        {mobile}
                    </div>
                </React.Fragment>
            );
        }
        else
        {
            return (
                <div className="configurator-dropdown">
                    <button className="configurator-dropdown-button">
                    </button>
                    <div className="configurator-dropdown-content">
                    </div>
                </div>
            )
        }
    }
}

// -----------------------------------------------------------------------

// Dropdown spacing colors

class ConfiguratorDropdownSpacingColor extends Component
{
    render()
    {
        if (this.props.content_spacing_colors.length > 0)
        {
            if (this.props.configurator_spacing_type !== 0)
            {
                // Dropdown
                const dropdown = [];
                for (let i = 0; i < this.props.content_spacing_colors.length; i++)
                {
                    dropdown.push(
                        <div className="configurator-dropdown-content-items clearfix" key={i}>
                            <button className="configurator-dropdown-item" onClick={() => this.props.function_configurator_spacing_color(i)}>
                                {DropdownItemColor('#'+this.props.content_spacing_colors[i][0])}
                                {DropdownItemBorder(this.props.configurator_spacing_color === i)}
                                {DropdownItemLabel(this.props.configurator_spacing_color === i, this.props.content_spacing_colors[i][1+this.props.translation_language_index])}
                            </button>
                        </div>
                    );
                }

                // Mobile button
                let mobile = '';
                if (this.props.configurator_mobile === null)
                {
                    mobile = (
                        <div className="configurator-dropdown-mobile">
                            <button className="configurator-dropdown-button" onClick={() => this.props.function_configurator_enable_mobile('spacing-color')}>
                                <div className="configurator-dropdown-button-content">
                                    <div className="configurator-dropdown-description">{this.props.function_translate('configurator_spacing_color')}</div>
                                    {this.props.content_spacing_colors[this.props.configurator_spacing_color][1+this.props.translation_language_index]}
                                </div>
                            </button>
                        </div>
                    );
                }
                else
                {
                    // Mobile dropdown
                    if (this.props.configurator_mobile === 'spacing-color')
                    {
                        let style = { left: '32px' };
                        if (this.props.view_mode === 1)
                            style = { left: '16px' };

                        mobile = (
                            <div className="configurator-dropdown-content-mobile" style={style}>
                                <div className="configurator-dropdown-back" onClick={() => this.props.function_configurator_disable_mobile()}><div className="configurator-dropdown-content-mobile-icon"></div>{this.props.function_translate('configurator_close')}</div>
                                <div className="configurator-dropdown-item-mobile-title">{this.props.function_translate('configurator_spacing_color')}</div>
                                {dropdown}
                            </div>
                        );
                    }
                }

                return (
                    <React.Fragment>
                        <div className="d-none d-sm-block">
                            <div className="configurator-dropdown">
                                <button className="configurator-dropdown-button" id="button-spacing-color">
                                    <div className="configurator-dropdown-button-content">
                                        <div className="configurator-dropdown-description">{this.props.function_translate('configurator_spacing_color')}</div>
                                        {this.props.content_spacing_colors[this.props.configurator_spacing_color][1+this.props.translation_language_index]}
                                    </div>
                                </button>
                                <div className="configurator-dropdown-content">
                                    {dropdown}
                                </div>
                            </div>
                        </div>
                        <div className="d-block d-sm-none">
                            {mobile}
                        </div>
                    </React.Fragment>
                );
            }
            else
            {
                // Mobile button
                let mobile = '';
                if (this.props.configurator_mobile === null)
                {
                    mobile = (
                        <div className="configurator-dropdown-mobile">
                            <button className="configurator-dropdown-button" onClick={() => this.props.function_configurator_enable_mobile('spacing-color')}>
                                <div className="configurator-dropdown-button-content">
                                    <div className="configurator-dropdown-description">{this.props.function_translate('configurator_spacing_color')}</div>
                                    {this.props.function_translate('stone_spacing_none')}
                                </div>
                            </button>
                        </div>
                    );
                }
                else
                {
                    // Mobile dropdown
                    if (this.props.configurator_mobile === 'spacing-color')
                    {
                        let style = { left: '32px' };
                        if (this.props.view_mode === 1)
                            style = { left: '16px' };

                        mobile = (
                            <div className="configurator-dropdown-content-mobile" style={style}>
                                <div className="configurator-dropdown-back" onClick={() => this.props.function_configurator_disable_mobile()}><div className="configurator-dropdown-content-mobile-icon"></div>{this.props.function_translate('configurator_close')}</div>
                                <div className="configurator-dropdown-item-mobile-title">{this.props.function_translate('configurator_spacing_color')}</div>
                                <div className="configurator-dropdown-content-items clearfix">
                                    <button className="configurator-dropdown-item">
                                        {DropdownItemColor('#000000')}
                                        {DropdownItemBorder(true)}
                                        {DropdownItemLabel(true, this.props.function_translate('stone_spacing_none'))}
                                    </button>
                                </div>
                            </div>
                        );
                    }
                }

                return (
                    <React.Fragment>
                        <div className="d-none d-sm-block">
                            <div className="configurator-dropdown">
                                <button className="configurator-dropdown-button" id="button-spacing-color">
                                    <div className="configurator-dropdown-button-content">
                                        <div className="configurator-dropdown-description">{this.props.function_translate('configurator_spacing_color')}</div>
                                        {this.props.function_translate('stone_spacing_none')}
                                    </div>
                                </button>
                                <div className="configurator-dropdown-content">
                                    <div className="configurator-dropdown-content-items clearfix">
                                        <button className="configurator-dropdown-item">
                                            {DropdownItemColor('#000000')}
                                            {DropdownItemBorder(true)}
                                            {DropdownItemLabel(true, this.props.function_translate('stone_spacing_none'))}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-block d-sm-none">
                            {mobile}
                        </div>
                    </React.Fragment>
                )
            }
        }
        else
        {
            return (
                <div className="configurator-dropdown">
                    <button className="configurator-dropdown-button">
                    </button>
                    <div className="configurator-dropdown-content">
                    </div>
                </div>
            )
        }
    }
}

// -----------------------------------------------------------------------

// Dropdown bond types

class ConfiguratorDropdownBondType extends Component
{
    render()
    {
        if (this.props.content_bond_types.length > 0
        && this.props.precompute_stone_name !== undefined)
        {
            // Dropdown
            const dropdown = [];
            for (let i = 0; i < this.props.content_bond_types.length; i++)
            {
                dropdown.push(
                    <div className="configurator-dropdown-content-items clearfix" key={i}>
                        <button className="configurator-dropdown-item" onClick={() => this.props.function_configurator_bond_type(i)}>
                            {DropdownItemImage('url(../texture/preview/'+this.props.precompute_stone_name+'_standard_000000_'+this.props.content_bond_types[i][0]+'_h_preview.jpg)')}
                            
                            {DropdownItemBorder(this.props.configurator_bond_type === i)}
                            {DropdownItemLabel(this.props.configurator_bond_type === i, this.props.content_bond_types[i][1+this.props.translation_language_index])}
                        </button>
                    </div>
                );
            }

            // Mobile button
            let mobile = '';
            if (this.props.configurator_mobile === null)
            {
                mobile = (
                    <div className="configurator-dropdown-mobile">
                        <button className="configurator-dropdown-button" onClick={() => this.props.function_configurator_enable_mobile('bond-type')}>
                            <div className="configurator-dropdown-button-content">
                                <div className="configurator-dropdown-description">{this.props.function_translate('configurator_bond_type')}</div>
                                {this.props.content_bond_types[this.props.configurator_bond_type][1+this.props.translation_language_index]}
                            </div>
                        </button>
                    </div>
                );
            }
            else
            {
                // Mobile dropdown
                if (this.props.configurator_mobile === 'bond-type')
                {
                    let style = { left: '32px' };
                    if (this.props.view_mode === 1)
                        style = { left: '16px' };

                    mobile = (
                        <div className="configurator-dropdown-content-mobile" style={style}>
                            <div className="configurator-dropdown-back" onClick={() => this.props.function_configurator_disable_mobile()}><div className="configurator-dropdown-content-mobile-icon"></div>{this.props.function_translate('configurator_close')}</div>
                            <div className="configurator-dropdown-item-mobile-title">{this.props.function_translate('configurator_bond_type')}</div>
                            {dropdown}
                        </div>
                    );
                }
            }

            return (
                <React.Fragment>
                    <div className="d-none d-sm-block">
                        <div className="d-none d-sm-block">
                            <div className="configurator-dropdown">
                                <button className="configurator-dropdown-button" id="button-bond-type">
                                    <div className="configurator-dropdown-button-content">
                                        <div className="configurator-dropdown-description">{this.props.function_translate('configurator_bond_type')}</div>
                                        {this.props.content_bond_types[this.props.configurator_bond_type][1+this.props.translation_language_index]}
                                    </div>
                                </button>
                                <div className="configurator-dropdown-content">
                                    {dropdown}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-block d-sm-none">
                        {mobile}
                    </div>
                </React.Fragment>
            )
        }
        else
        {
            return (
                <div className="configurator-dropdown">
                    <button className="configurator-dropdown-button">
                    </button>
                    <div className="configurator-dropdown-content">
                    </div>
                </div>
            )
        }
    }
}

// -----------------------------------------------------------------------

// Dropdown bond orientations

class ConfiguratorDropdownBondOrientation extends Component
{
    render()
    {
        if (this.props.content_bond_orientations.length > 0
        && this.props.precompute_stone_name !== undefined)
        {
            // Dropdown
            const dropdown = [];
            for (let i = 0; i < this.props.content_bond_orientations.length; i++)
            {
                dropdown.push(
                    <div className="configurator-dropdown-content-items clearfix" key={i}>
                        <button className="configurator-dropdown-item" onClick={() => this.props.function_configurator_bond_orientation(i)}>
                            {DropdownItemImage('url(../texture/preview/'+this.props.precompute_stone_name+'_standard_000000_halfsteensverband_'+this.props.content_bond_orientations[i][0]+'_preview.jpg)')}

                            {DropdownItemBorder(this.props.configurator_bond_orientation === i)}
                            {DropdownItemLabel(this.props.configurator_bond_orientation === i, this.props.content_bond_orientations[i][1+this.props.translation_language_index])}
                        </button>
                    </div>
                );
            }

            // Mobile button
            let mobile = '';
            if (this.props.configurator_mobile === null)
            {
                mobile = (
                    <div className="configurator-dropdown-mobile">
                        <button className="configurator-dropdown-button" onClick={() => this.props.function_configurator_enable_mobile('bond-orientation')}>
                            <div className="configurator-dropdown-button-content">
                                <div className="configurator-dropdown-description">{this.props.function_translate('configurator_orientation')}</div>
                                {this.props.content_bond_orientations[this.props.configurator_bond_orientation][1+this.props.translation_language_index]}
                            </div>
                        </button>
                    </div>
                );
            }
            else
            {
                // Mobile dropdown
                if (this.props.configurator_mobile === 'bond-orientation')
                {
                    let style = { left: '32px' };
                    if (this.props.view_mode === 1)
                        style = { left: '16px' };

                    mobile = (
                        <div className="configurator-dropdown-content-mobile" style={style}>
                            <div className="configurator-dropdown-back" onClick={() => this.props.function_configurator_disable_mobile()}><div className="configurator-dropdown-content-mobile-icon"></div>{this.props.function_translate('configurator_close')}</div>
                            <div className="configurator-dropdown-item-mobile-title">{this.props.function_translate('configurator_orientation')}</div>
                            {dropdown}
                        </div>
                    );
                }
            }

            return (
                <React.Fragment>
                    <div className="d-none d-sm-block">
                        <div className="configurator-dropdown">
                            <button className="configurator-dropdown-button" id="button-bond-orientation">
                                <div className="configurator-dropdown-button-content">
                                    <div className="configurator-dropdown-description">{this.props.function_translate('configurator_orientation')}</div>
                                    {this.props.content_bond_orientations[this.props.configurator_bond_orientation][1+this.props.translation_language_index]}
                                </div>
                            </button>
                            <div className="configurator-dropdown-content">
                                {dropdown}
                            </div>
                        </div>
                    </div>
                    <div className="d-block d-sm-none">
                        {mobile}
                    </div>
                </React.Fragment>
            )
        }
        else
        {
            return (
                <div className="configurator-dropdown">
                    <button className="configurator-dropdown-button">
                    </button>
                    <div className="configurator-dropdown-content">
                    </div>
                </div>
            )
        }
    }
}

// -----------------------------------------------------------------------

// Dropdown seperator

class ConfiguratorDropdownSeperator extends Component
{
    render()
    {
        if (this.props.configurator_mobile === null)
        {
            return (
                <div className="configurator-dropdown-seperator"></div>
            );
        }
        else
        {
            return (
                <React.Fragment></React.Fragment>
            );
        }
    }
}

// -----------------------------------------------------------------------

// Info

class ConfiguratorInfo extends Component
{
    render()
    {
        return (
            <div className="configurator-info" onClick={() => this.props.function_popup_open('howtouse')}>
                <div className="configurator-info-text">?</div>
            </div>
        );
    }
}

// -----------------------------------------------------------------------

// Configurator

class Configurator extends Component
{
    componentDidMount()
    {
        this.props.function_configurator_adapt_dropdowns();
    }
    
    render()
    {
        // Pre-compute collection+stone name
        let precompute_stone_name = undefined;
        if (this.props.content_stone_collections.length > 0 && this.props.content_stone_types.length > 0)
        {
            let single = 0
            for (let i = 0; i < this.props.content_stone_types.length; i++)
            {
                if (this.props.configurator_stone_multi[i] > 0)
                {
                    single = i;
                    break;
                }
            }

            precompute_stone_name = this.props.content_stone_collections[this.props.configurator_stone_collection][1]+'_'+this.props.content_stone_types[single][0];
        }

        return (
            <React.Fragment>
                <ConfiguratorBackButton
                    view_mode={this.props.view_mode}
                    function_view_back_to_intro_items={this.props.function_view_back_to_intro_items} />

                <ConfiguratorTitle
                    function_translate={this.props.function_translate} />
                
                <ConfiguratorDropdownCollection
                    translation_language_index={this.props.translation_language_index}
                    precompute_stone_name={precompute_stone_name}
                    content_stone_collections={this.props.content_stone_collections}
                    view_mode={this.props.view_mode}
                    configurator_stone_collection={this.props.configurator_stone_collection}
                    configurator_mobile={this.props.configurator_mobile}
                    function_translate={this.props.function_translate}
                    function_configurator_stone_collection={this.props.function_configurator_stone_collection}
                    function_configurator_enable_mobile={this.props.function_configurator_enable_mobile}
                    function_configurator_disable_mobile={this.props.function_configurator_disable_mobile} />

                <ConfiguratorDropdownSeperator
                    configurator_mobile={this.props.configurator_mobile} />

                <ConfiguratorDropdownStone
                    translation_language_index={this.props.translation_language_index}
                    content_stone_collections={this.props.content_stone_collections}
                    content_stone_types={this.props.content_stone_types}
                    view_mode={this.props.view_mode}
                    configurator_stone_collection={this.props.configurator_stone_collection}
                    configurator_stone_multi={this.props.configurator_stone_multi}
                    configurator_mobile={this.props.configurator_mobile}
                    function_translate={this.props.function_translate}
                    function_configurator_stone_select_multi_increase={this.props.function_configurator_stone_select_multi_increase}
                    function_configurator_stone_select_multi_decrease={this.props.function_configurator_stone_select_multi_decrease}
                    function_configurator_enable_mobile={this.props.function_configurator_enable_mobile}
                    function_configurator_disable_mobile={this.props.function_configurator_disable_mobile} />

                <ConfiguratorDropdownSeperator
                    configurator_mobile={this.props.configurator_mobile} />

                <ConfiguratorDropdownSpacingType
                    translation_language_index={this.props.translation_language_index}
                    precompute_stone_name={precompute_stone_name}
                    content_spacing_types={this.props.content_spacing_types}
                    view_mode={this.props.view_mode}
                    configurator_spacing_type={this.props.configurator_spacing_type}
                    configurator_bond_type={this.props.configurator_bond_type}
                    configurator_bond_orientation={this.props.configurator_bond_orientation}
                    configurator_mobile={this.props.configurator_mobile}
                    function_translate={this.props.function_translate}
                    function_configurator_spacing_type={this.props.function_configurator_spacing_type}
                    function_configurator_enable_mobile={this.props.function_configurator_enable_mobile}
                    function_configurator_disable_mobile={this.props.function_configurator_disable_mobile} />

                <ConfiguratorDropdownSeperator
                    configurator_mobile={this.props.configurator_mobile} />
                
                <ConfiguratorDropdownSpacingColor
                    translation_language_index={this.props.translation_language_index}
                    content_spacing_colors={this.props.content_spacing_colors}
                    view_mode={this.props.view_mode}
                    configurator_spacing_type={this.props.configurator_spacing_type}
                    configurator_spacing_color={this.props.configurator_spacing_color}
                    configurator_mobile={this.props.configurator_mobile}
                    function_translate={this.props.function_translate}
                    function_configurator_spacing_color={this.props.function_configurator_spacing_color}
                    function_configurator_enable_mobile={this.props.function_configurator_enable_mobile}
                    function_configurator_disable_mobile={this.props.function_configurator_disable_mobile} />

                <ConfiguratorDropdownSeperator
                    configurator_mobile={this.props.configurator_mobile} />
                
                <ConfiguratorDropdownBondType
                    translation_language_index={this.props.translation_language_index}
                    precompute_stone_name={precompute_stone_name}
                    content_bond_types={this.props.content_bond_types}
                    view_mode={this.props.view_mode}
                    configurator_bond_type={this.props.configurator_bond_type}
                    configurator_mobile={this.props.configurator_mobile}
                    function_translate={this.props.function_translate}
                    function_configurator_bond_type={this.props.function_configurator_bond_type}
                    function_configurator_enable_mobile={this.props.function_configurator_enable_mobile}
                    function_configurator_disable_mobile={this.props.function_configurator_disable_mobile} />

                <ConfiguratorDropdownSeperator
                    configurator_mobile={this.props.configurator_mobile} />

                <ConfiguratorDropdownBondOrientation
                    translation_language_index={this.props.translation_language_index}
                    precompute_stone_name={precompute_stone_name}
                    content_bond_orientations={this.props.content_bond_orientations}
                    view_mode={this.props.view_mode}
                    configurator_bond_orientation={this.props.configurator_bond_orientation}
                    configurator_mobile={this.props.configurator_mobile}
                    function_translate={this.props.function_translate}
                    function_configurator_bond_orientation={this.props.function_configurator_bond_orientation}
                    function_configurator_enable_mobile={this.props.function_configurator_enable_mobile}
                    function_configurator_disable_mobile={this.props.function_configurator_disable_mobile} />

                <ConfiguratorDropdownSeperator
                    configurator_mobile={this.props.configurator_mobile} />

                <ConfiguratorInfo
                    function_popup_open={this.props.function_popup_open} />

            </React.Fragment>
        )
    }
}

export default Configurator