import React, { Component } from 'react';
import axios from 'axios';
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

// Download

class Download extends Component
{
    constructor(props)
    {
        // Props
        super(props);

        this.download_token = null;

        this.state = {
            token: null,
            valid: null,
            link: null
        };

        this.download_get_token = this.download_get_token.bind(this);
        this.download_get_downloadfile = this.download_get_downloadfile.bind(this);
        this.download_zip = this.download_zip.bind(this);
    }

    download_get_token()
    {
        // Get query arguments
        let url_querystring =  window.location.search;
                            
        if (this.debug === true)
            console.log(url_querystring);

        function parseQuery(query)
        {
            let object = {};
                
            if (query.indexOf('?') !== -1)
            {
                query = query.split('?');		
                query = query[1];
            }

            let parse = query.split("&");

            for (let i = 0; i < parse.length; i++)
            {
                let pair = parse[i].split('=');
                let key = decodeURIComponent(pair[0]);
                if (key.length === 0) continue;
                let value = decodeURIComponent(pair[1].replace("+"," "));

                if (object[key] === undefined)
                    object[key] = value;
                else if (object[key] instanceof Array)
                    object[key].push(value);
                else
                    object[key] = [object[key],value];
            }

            return object;
        };

        // Get token
        let url_parameters = parseQuery(url_querystring);
        if ('token' in url_parameters)
        {
            this.setState({
                token: url_parameters['token']
            }, () => this.download_get_downloadfile());
        }
    }

    download_get_downloadfile()
    {
        // Get download link
        if (this.state.token !== null)
        {
            const get_download = async() => {
                try
                {   
                    const data = {
                        token: this.state.token
                    }

                    const response = await axios.get(this.props.ip+'/backend/api/get_download', {params: data});
                    
                    this.setState({
                        valid: true,
                        link: response.data
                    }, () => this.download_zip(this.state.link));
                }

                catch(error)
                {
                    this.setState({
                        valid: false
                    });
                }
            }

            get_download();
        }
    }

    download_zip(link)
    {
        const download = async() => {
            try
            {
                await axios.get(this.props.ip+'/download/'+link, { responseType: 'arraybuffer',headers: {'Content-Type': 'multipart/form-data'}}).then(result => {
                    let blob = new Blob([result.data], { type: 'application/zip' })

                    const downloadUrl = URL.createObjectURL(blob)
                    let a = document.createElement("a"); 
                    a.href = downloadUrl;
                    a.download = this.state.link;
                    document.body.appendChild(a);
                    a.click();
                });
            }

            catch(error)
            {
                console.log(error);
            }
        }

        download();
    }

    componentDidMount()
    {
        this.download_get_token();
    }

    render()
    {
        let content = '';

        if (this.state.valid === true)
        {
            content = (
                <React.Fragment>
                    <div className="start-label-highlight">{this.props.function_translate('texturegenerator')}</div>
                    <h1 className="start-title">{this.props.function_translate('download_start_title')}</h1>
                    <p className="start-text"> {this.props.function_translate('download_start_sub')} <a href={this.props.ip+'/download/'+this.state.link} target="_blank" rel="noopener noreferrer" className="start-text-link">{this.props.function_translate('download_start_here')}</a>.</p>
                </React.Fragment>
            );
        }

        if (this.state.valid === false)
        {
            content = (
                <React.Fragment>
                    <div className="start-label-highlight">{this.props.function_translate('texturegenerator')}</div>
                    <h1 className="start-title">{this.props.function_translate('download_expired_title')}</h1>
                    <p className="start-text">{this.props.function_translate('download_expired_sub')}</p>
                </React.Fragment>
            );
        }

        return (
            <section className="start-section">
                <div className="container start-container">
                    <div className="row">
                        <div className="start-column col-12 col-sm-8 col-lg-6 col-xl-5">
                            {content}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Download