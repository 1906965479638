import React, { Component } from 'react';

// -----------------------------------------------------------------------

// View zoom in (2D mode)
class ViewZoomIn2D extends Component
{
    render()
    {
        const window_style = {
            top: (this.props.window_height-89)+'px'
        }

        if (this.props.view_rows > this.props.view_rows_min)
        {
            return (
                <button className="view-zoomin" style={window_style} onClick={() => this.props.function_view_rows_zoomin()}></button>
            )
        }  
        else
        {
            return (
                <React.Fragment>
                </React.Fragment>
            );
        }
    }
}

// View zoom out (2D mode)
class ViewZoomOut2D extends Component
{
    render()
    {
        const window_style = {
            top: (this.props.window_height-89)+'px'
        }

        if (this.props.view_rows < this.props.view_rows_max)
        {
            return (
                <button className="view-zoomout" style={window_style} onClick={() => this.props.function_view_rows_zoomout()}></button> 
            )
        }
        else
        {
            return (
                <React.Fragment>
                </React.Fragment>
            );
        }
    }
}

// View mode photo
class ViewModePhoto extends Component
{   
    render()
    {
        let button_style;
        let thumbnail_style;
        let mobile_style;
        
        if (this.props.photo_current === null)
        {
            button_style = {
                top: (this.props.window_height-89)+'px'
            }

            thumbnail_style = {
                backgroundImage: 'url(../thumbnail/thumbnail_photo07.jpg'
            }

            mobile_style = {
                backgroundImage: 'url(../thumbnail/thumbnail_photo07.jpg',
                top: (this.props.window_height-89)+'px'
            }
        }
        else
        {
            button_style = {
                top: (this.props.window_height-89)+'px'
            }

            thumbnail_style = {
                backgroundImage: 'url(../thumbnail/thumbnail_photo'+this.props.content_photos[this.props.photo_current][0]+'.jpg'
            }

            mobile_style = {
                backgroundImage: 'url(../thumbnail/thumbnail_photo'+this.props.content_photos[this.props.photo_current][0]+'.jpg',
                top: (this.props.window_height-89)+'px'
            }
        }

        return (
            <React.Fragment>
                <button className="view-mode-photo d-none d-md-block" style={button_style} onClick={() => this.props.function_view_mode(1)}><span>{this.props.function_translate('view_switch_visualisation')}</span><div className="view-mode-photo-background" style={thumbnail_style}></div></button>

                <button className="view-mode-photo-mobile d-block d-md-none" style={mobile_style} onClick={() => this.props.function_view_mode(1)}><span>{this.props.function_translate('view_switch_visualisation')}</span></button>
            </React.Fragment>
        )
    }
}

// View zoom in (Photo mode)
class ViewZoomInPhoto extends Component
{
    render()
    {
        const window_style_mobile = {
            top: (this.props.window_height-73)+'px'
        }

        const window_style_desktop = {
            top: (this.props.window_height-145)+'px'
        }

        if (this.props.photo_loaded === true && this.props.photo_size === 1)
        {
            return (
                <React.Fragment>
                    <button className="view-photo-zoomin d-block d-lg-none" style={window_style_mobile} onClick={() => this.props.function_photo_size(0)}></button>

                    <button className="view-photo-zoomin d-none d-lg-block" style={window_style_desktop} onClick={() => this.props.function_photo_size(0)}></button>
                </React.Fragment>
            )
        }
        else
        {
            return (
                <React.Fragment>
                </React.Fragment>
            );
        }
    }
}

// View zoom out (Photo mode)
class ViewZoomOutPhoto extends Component
{
    render()
    {
        const window_style_mobile = {
            top: (this.props.window_height-73)+'px'
        }

        const window_style_desktop = {
            top: (this.props.window_height-145)+'px'
        }

        if (this.props.photo_loaded === true && this.props.photo_size === 0)
        {
            return (
                <React.Fragment>
                    <button className="view-photo-zoomout d-block d-lg-none" style={window_style_mobile} onClick={() => this.props.function_photo_size(1)}></button>

                    <button className="view-photo-zoomout d-none d-lg-block" style={window_style_desktop} onClick={() => this.props.function_photo_size(1)}></button>
                </React.Fragment>
            )
        }
        else
        {
            return (
                <React.Fragment>
                </React.Fragment>
            );
        }
    }
}

// View mode 2D
class ViewMode2D extends Component
{      
    render()
    {
        const window_style_mobile = {
            top: (this.props.window_height-73)+'px'
        }

        const window_style_desktop = {
            top: (this.props.window_height-145)+'px'
        }

        if (this.props.photo_loaded === true)
        {
            return (
                <React.Fragment>
                    <button className="view-mode-2d d-block d-lg-none" style={window_style_mobile} onClick={() => this.props.function_view_mode(0)}><span>{this.props.function_translate('view_switch_texture')}</span></button>

                    <button className="view-mode-2d d-none d-lg-block" style={window_style_desktop} onClick={() => this.props.function_view_mode(0)}><span>{this.props.function_translate('view_switch_texture')}</span></button>
                </React.Fragment>
            )
        }
        else
        {
            return (
                <React.Fragment>
                </React.Fragment>
            );
        }
    }
}

// View photo carrousel
class ViewPhotoCarrousel extends Component
{
    render()
    {
        const carrousel = [];
        
        for (let i = 0; i < this.props.content_photos.length; i++)
        {
            let thumbnail_style;
            let thumbnail_class = 'view-photo-carrousel-thumbnail';

            if (i === this.props.photo_current)
            {
                thumbnail_style = {
                    backgroundImage: 'url(../thumbnail/thumbnail_photo'+this.props.content_photos[i][0]+'.jpg'
                }

                thumbnail_class = 'view-photo-carrousel-thumbnail-selected';
            }
            else
            {
                thumbnail_style = {
                    backgroundImage: 'url(../thumbnail/thumbnail_photo'+this.props.content_photos[i][0]+'.jpg',
                    alpha: 1.0
                }

                thumbnail_class = 'view-photo-carrousel-thumbnail';
            }
            
            carrousel.push(
                <div className={thumbnail_class} key={i} style={thumbnail_style} onClick={() => this.props.function_photo_select(i)}></div>
            )
        }

        let window_style = {
            top: (this.props.window_height-72)+'px'
        };
        
        return (
            <div className="view-photo-carrousel-container d-none d-lg-block" style={window_style}>
                {carrousel}
            </div>
        )
    }
}

// View photo previous
class ViewPhotoPrevious extends Component
{
    render()
    {
        if (this.props.photo_current > 0 && this.props.photo_loaded === true)
        {
            const window_style = {
                top: (((this.props.window_height-72)/2)+24)+'px'
            }

            return (
                <button className="view-photo-previous" style={window_style} onClick={() => this.props.function_photo_previous()}></button>
            );
        }
        else
        {
            return (
                <React.Fragment>
                </React.Fragment>
            );
        }
    }
}

// View photo next
class ViewPhotoNext extends Component
{
    render()
    {
        if (this.props.photo_loaded === true && this.props.photo_current < this.props.content_photos.length-1)
        {
            const window_style = {
                top: (((this.props.window_height-72)/2)+24)+'px'
            }

            return (
                <button className="view-photo-next" style={window_style} onClick={() => this.props.function_photo_next()}></button>
            );
        }
        else
        {
            return (
                <React.Fragment>
                </React.Fragment>
            );
        }
    }
}

// View photo loading
class ViewPhotoLoading extends Component
{
    render()
    {
        let loading_class = 'loading-rotate-photo';
        if (this.props.photo_first === true)
        {
            loading_class = 'loading-rotate-intro';
        }

        if (this.props.photo_loaded === false)
        {
            const window_style = {
                height: (this.props.window_height-72)+'px'
            }

            return (
                <div className="loading-photo" style={window_style}>
                    <div className={loading_class}></div>
                </div>
            )
        }
        else
        {
            return (
                <React.Fragment>
                </React.Fragment>
            )
        }
    }
}

// -----------------------------------------------------------------------
                 
// View

class View extends Component
{
    render()
    {
        if (this.props.content_loaded === true)
        {
            if (this.props.view_mode === 0)
            {
                return (
                    <React.Fragment>
                        <ViewZoomIn2D
                            view_rows_min={this.props.view_rows_min}
                            view_rows={this.props.view_rows}
                            window_height={this.props.window_height}
                            function_view_rows_zoomin={this.props.function_view_rows_zoomin} />

                        <ViewZoomOut2D
                            view_rows_max={this.props.view_rows_max}
                            view_rows={this.props.view_rows}
                            window_height={this.props.window_height}
                            function_view_rows_zoomout={this.props.function_view_rows_zoomout} />

                        <ViewModePhoto
                            content_photos={this.props.content_photos}
                            photo_current={this.props.photo_current}
                            window_height={this.props.window_height}
                            function_translate={this.props.function_translate}
                            function_view_mode={this.props.function_view_mode} />
                    </React.Fragment>
                )
            }
            else
            {
                return (
                    <React.Fragment>
                        <ViewPhotoCarrousel
                            content_photos={this.props.content_photos}
                            photo_current={this.props.photo_current}
                            photo_loaded={this.props.photo_loaded}
                            window_height={this.props.window_height}
                            function_photo_select={this.props.function_photo_select} />

                        <ViewPhotoPrevious
                            photo_current={this.props.photo_current}
                            photo_loaded={this.props.photo_loaded}
                            window_height={this.props.window_height}
                            function_photo_previous={this.props.function_photo_previous} />

                        <ViewPhotoNext
                            content_photos={this.props.content_photos}
                            photo_current={this.props.photo_current}
                            photo_loaded={this.props.photo_loaded}
                            window_height={this.props.window_height}
                            function_photo_next={this.props.function_photo_next} />

                        <ViewZoomInPhoto
                            photo_loaded={this.props.photo_loaded}
                            photo_size={this.props.photo_size}
                            window_height={this.props.window_height}
                            function_photo_size={this.props.function_photo_size} />

                        <ViewZoomOutPhoto
                            photo_loaded={this.props.photo_loaded}
                            photo_size={this.props.photo_size}
                            window_height={this.props.window_height}
                            function_photo_size={this.props.function_photo_size} />

                        <ViewMode2D
                            photo_loaded={this.props.photo_loaded}
                            window_height={this.props.window_height}
                            function_translate={this.props.function_translate}
                            function_view_mode={this.props.function_view_mode} />

                        <ViewPhotoLoading
                            photo_loaded={this.props.photo_loaded}
                            photo_first={this.props.photo_first}
                            window_height={this.props.window_height} />
                        
                    </React.Fragment>
                )
            }
        }
    }
}

export default View