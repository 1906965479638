import React, { Component } from 'react';

// Favourites

class Favourites extends Component
{
    constructor(props)
    {
        // Props
        super(props);

        // References
        this.canvas_reference = [];
        this.canvas_enabled = [];
        for (let i = 0; i < this.props.favourites_stone_collection.length; i++)
        {
            this.canvas_reference.push(React.createRef());
            this.canvas_enabled.push(true);
        }

        this.canvas_disable = this.canvas_disable.bind(this);
        this.canvas_resize = this.canvas_resize.bind(this);
    }

    componentDidMount()
    {
        for (let i = 0; i < this.props.favourites_stone_collection.length; i++)
        {
            // Resize
            var canvas = document.getElementById('canvas_favourite_id'+i);
            canvas.width = 648;
            canvas.height = 286;

            // Draw
            var context = this.canvas_reference[i].current.getContext('2d', {willReadFrequently: true});

            if (this.props.favourites_spacing_type[i] === 0)
            {
                context.fillStyle = '#000000';
                context.fillRect(0, 0, canvas.width, canvas.height);
            }
            else
            {
                context.fillStyle = '#'+this.props.content_spacing_colors[this.props.favourites_spacing_color[i]][0];
                context.fillRect(0, 0, canvas.width, canvas.height);
            }
            
            if (this.props.favourites_diffuse_image[i].length === 1)
            {
                // Single stone image
                context.drawImage(this.props.favourites_diffuse_image[i][0], 0, 0);
            }
            else
            {
                // Create offscreen result canvas
                let canvas_result = this.canvas_reference[0].current.cloneNode();
                let context_result = canvas_result.getContext('2d', {willReadFrequently: true});
                canvas_result.width = 648;
                canvas_result.height = 286;
                context_result.globalCompositeOperation = "source-over";

                // Create offscreen diffuse canvas
                let canvas_diffuse = this.canvas_reference[0].current.cloneNode();
                let context_diffuse = canvas_diffuse.getContext('2d', {willReadFrequently: true});
                canvas_diffuse.width = 648;
                canvas_diffuse.height = 286;
                context_diffuse.globalCompositeOperation = "source-over";

                // Create offscreen mask canvas
                let canvas_mask = this.canvas_reference[0].current.cloneNode();
                let context_mask = canvas_mask.getContext('2d', {willReadFrequently: true});
                canvas_mask.width = 648;
                canvas_mask.height = 286;

                for (let j = 0; j < this.props.favourites_diffuse_image[i].length; j++)
                {
                    // Draw the mixmask
                    context_mask.globalCompositeOperation = "source-over";
                    context_mask.fillStyle = '#000000';
                    context_mask.fillRect(0, 0, canvas_mask.width, canvas_mask.height);
                    
                    context_mask.globalCompositeOperation = "lighter";
                    context_mask.drawImage(this.props.favourites_mixmask_image[i][j], 0, 0);

                    // Draw the diffuse layer
                    context_diffuse.drawImage(this.props.favourites_diffuse_image[i][j], 0, 0);

                    // Apply the mask to the diffuse layer
                    let imagedata_mask = context_mask.getImageData(0, 0, canvas_mask.width, canvas_mask.height);
                    let data_mask = imagedata_mask.data;

                    let imagedata_diffuse = context_diffuse.getImageData(0, 0, canvas_diffuse.width, canvas_diffuse.height);
                    let data_diffuse = imagedata_diffuse.data;
                    
                    let k = 0;
                    while (k < data_diffuse.length)
                    {
                        data_diffuse[k+3] = data_mask[k];

                        k += 4;
                    }

                    context_diffuse.putImageData(imagedata_diffuse, 0, 0);

                    // Draw to the result layer
                    context_result.drawImage(canvas_diffuse, 0, 0);
                }

                // Draw result
                context.drawImage(canvas_result, 0, 0);
                
                // Cleanup
                canvas_result = null;
                canvas_diffuse = null;
                canvas_mask = null;
            }
        }

        // Resize immediately
        this.canvas_resize();

        // When the window is resized, queue up for canvas resize
        window.addEventListener("resize", this.canvas_resize);

        this.props.function_message_post_height();
    }

    componentWillUnmount()
    {
        for (let i = 0; i < this.props.favourites_stone_collection.length; i++)
        {
            // Remove
            this.canvas_reference[i] = null;
        }

        // Remove listener
        window.removeEventListener("resize", this.canvas_resize);
    }

    canvas_disable(index)
    {
        this.canvas_enabled[index] = false;
        this.forceUpdate();
    }

    canvas_resize()
    {
        for (let i = 0; i < this.props.favourites_stone_collection.length; i++)
        {
            let item = document.getElementById('favourite_item_id'+i);
            item.style.height = (item.offsetWidth / (648/286))+"px";
        }
    }

    render()
    {
        const items = [];

        for (let i = 0; i < this.props.favourites_stone_collection.length; i++)
        {
            if (this.canvas_enabled[i] === true)
            {
                let date = this.props.favourites_date[i];
                
                let link = this.props.ip;
                
                // Collection
                link += '?collection='+this.props.content_stone_collections[this.props.favourites_stone_collection[i]][1];
                
                // Stones
                for (let j = 0; j < this.props.favourites_stone[i].length; j++)
                {
                    if (this.props.favourites_stone[i][j] > 0)
                    {
                        link += '&'+this.props.content_stone_types[j][0]+'='+this.props.favourites_stone[i][j];
                    }
                }
                
                // Spacing type
                link += '&spacing='+this.props.content_spacing_types[this.props.favourites_spacing_type[i]][0];

                // Spacing color
                link += '&color='+this.props.content_spacing_colors[this.props.favourites_spacing_color[i]][0];

                // Bond type
                link += '&bond='+this.props.content_bond_types[this.props.favourites_bond_type[i]][0];

                // Bond orientation
                link += '&orientation='+this.props.content_bond_orientations[this.props.favourites_bond_orientation[i]][0];

                items.push(
                    <div className="col-xl-3 col-md-4 col-sm-6" key={i}>
                        <div id={"favourite_item_id"+i} className="favourite-item">
                            <div className="favourite-delete" onClick={() => {
                                this.props.function_favourites_delete_cookie(i);
                                this.canvas_disable(i);
                            }}></div>

                            <a href={link} target="_blank" rel="noreferrer"><canvas className="favourite-item-canvas" ref={this.canvas_reference[i]} id={"canvas_favourite_id"+i}></canvas></a>

                            <div className="favourite-item-displayname">{this.props.function_translate('favourites_saved_on')} {date}</div>
                        </div>
                    </div>
                );
            }
        }

        return (
            <React.Fragment>
                <div className="row">
                    {items}
                </div>
            </React.Fragment>
        );
    }
}

export default Favourites