import React, { Component } from 'react';

// -----------------------------------------------------------------------

// Loading

class LoadingIntro extends Component
{
    componentDidMount()
    {
        this.props.function_message_post_height();
    }

    render()
    {
        return (
            <section>
                <div className="container loading-container">
                    <div className="row">
                        <div className="loading-intro">
                            <div className="loading-rotate-intro"></div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

class LoadingFavourite extends Component
{
    componentDidMount()
    {
        this.props.function_message_post_height();
    }
    
    render()
    {
        return (
            <section>
                <div className="container loading-container">
                    <div className="row">
                        <div className="loading-rotate-intro"></div>
                    </div>
                </div>
            </section>
        )
    }
}

export { LoadingIntro, LoadingFavourite };